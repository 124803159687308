<template>
  <div>
    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <p class="hidden text-lg font-bold text-center pc:text-5xl text-neutral-950 mb-11 pc:block">비밀번호 찾기</p>
      <div class="px-5 border border-solid py-7 pc:py-11 border-neutral-200 rounded-2xl">
        <div class="max-w-lg mx-auto">
          <p
            class="py-3 text-lg font-bold text-center rounded px-7- pc:px-24 pc:py-4 text-neutral-950 pc:text-2xl bg-zinc-100 mb-14 pc:mb-20"
          >
            {{ $route.query.userEmail }}
          </p>
          <ul class="flex flex-col mb-7 pc:mb-20">
            <li class="flex gap-1">
              <p class="text-xs pc:text-lg text-neutral-500">*</p>
              <p class="text-xs pc:text-lg text-neutral-500">회원님의 아이디는 다음과 같습니다.</p>
            </li>
            <li class="flex gap-1">
              <p class="text-xs pc:text-lg text-neutral-500">*</p>
              <p class="text-xs pc:text-lg text-neutral-500">
                임시 비밀번호 발급 시, 등록된 이메일로 임시 비밀번호가 발송됩니다.
              </p>
            </li>
            <li class="flex gap-1">
              <p class="text-xs pc:text-lg text-neutral-500">*</p>
              <p class="text-xs pc:text-lg text-neutral-500">
                임시 비밀번호 발급 후 [마이페이지] > [회원정보수정] 에서 비밀번호를 변경해 주세요.
              </p>
            </li>
          </ul>
          <p
            class="max-w-xs py-4 mx-auto text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl mb-11"
            @click="findUserPW.sendPasswordResetEmail"
          >
            임시 비밀번호 발급
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain;
    const baseUrl = "https://stage-lifeedu.carrotenglish.net/home";

    const findUserPW = reactive({
      userInfo: {
        userEmail: "",
      },

      sendPasswordResetEmail() {
        const userEmail = route.query.userEmail;
        const siteUrlWithQuery = `${baseUrl}?idxLeSiteMain=${idxLeSiteMain}`;

        axios.put(`/user/auth/sendPasswordResetEmail/?userEmail=${userEmail}&siteUrl=${siteUrlWithQuery}`).then(function (res) {
          if (res.data.success === true) {
            alert("등록된 이메일로 임시 비밀번호가 발송되었습니다.");
          } else {
            alert(res.data.message);
          }
        });
      },
    });

    onMounted(() => {});

    return {
      findUserPW,
      baseUrl,
    };
  },
};
</script>
<style scoped></style>
