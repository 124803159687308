<template>
  <div class="p-4">
    <div class="p-2 mb-5">
      <p class="mb-2 text-xl font-semibold">평생교육 홈페이지 관리자 안내</p>
      <ul class="flex flex-col gap-2">
        <li>- <span class="text-red-500">[Compnay] </span>클릭 시, 홈페이지 수정페이지로 이동합니다.</li>
        <li>- <span class="text-red-500">[과정명] </span>클릭 시, 수강신청 수정페이지로 이동합니다.</li>
        <li>
          - 학습자가 보게 될 화면은 미리보기
          <span class="text-red-500">[View] </span>버튼을 클릭하여 확인 가능합니다.
        </li>
        <li>
          - 수강신청 리스트는 신청자/대기인원의
          <span class="text-red-500">[숫자]</span>를 클릭하여 확인 가능합니다.
        </li>
        <li>
          - 수강신청 취소자 리스트는 취소자의
          <span class="text-red-500">[숫자]</span>를 클릭하여 확인 가능합니다.
        </li>
        <li>
          - 공지사항 관리
          <span class="text-red-500">[View] </span>버튼을 클릭하여 공지사항 관리가 가능합니다.
        </li>
        <li class="flex flex-wrap items-center">
          <p>- 평생교육 글로벌역량 플랫폼 자동배정 안내</p>
          <div class="cursor-pointer w-[16px] pt-1 ml-1" @click="listPage.openModal()">
            <img src="@/lifeeduAdmin/assets/images/questionMark.png" />
          </div>
        </li>
        <li v-if="listPage.modalOnOff" class="static">
          <div class="fixed inset-0 flex justify-center items-center z-[1]">
            <div class="p-5 bg-white rounded-xl shadow-[3px_10px_10px_rgba(0,0,0,0.8)] max-w-[870px]">
              <div class="flex justify-end mb-5">
                <div class="cursor-pointer font-bold" @click="listPage.closeModal()">X</div>
              </div>
              <div>
                <p class="text-xl font-medium text-center pb-[15px]">평생교육 글로벌역량 플랫폼 자동배정 안내</p>
                <ul class="flex flex-col gap-5 mb-5">
                  <li>
                    <span class="font-bold">[1:1 수업]</span>
                    <p>
                      수강신청을 완료하고 결제가 이루어지는 즉시, 학습자가 기재한
                      <span class="font-bold">[희망 시간 1] + [희망 시간 2]</span> 기준으로 자동배정이 진행됩니다.<br />
                      만약, 희망 시간 내 배정 가능한 강사가 없는 경우, 해당 수강신청 자동 배정은 진행되지 않습니다.<br />
                      자동배정까지 걸리는 시간은 <span class="font-bold underline underline-offset-1">약 1분 내외로</span>
                      <span class="font-bold">강사와 교재까지 자동배정</span> 됩니다. <br />
                      (단, 수강 신청이 집중되는 시간에는 약간의 지연이 발생할 수 있음.)
                    </p>
                  </li>
                  <br /><br />
                  <li>
                    <p class="font-bold">[1:N 그룹 수업]</p>
                    <p>
                      수강신청을 완료하고 결제가 이루어지는 즉시, <span class="font-bold">학습자가 선택한 수업 기준</span>으로
                      자동배정이 진행됩니다.<br />
                      만약, 선택한 수업 내 배정 가능한 강사가 없을 경우, 해당 수강신청 자동 배정은 진행되지 않습니다.
                      <br /><br />
                      렉쳐 배정은 수업시작일 기준 +7일 사이
                      <span class="font-bold"
                        >수업 횟수에 따른 요일 기준(주 2회 : 화/목, 주 3회 : 월/수/금, 주 5회 : 월/화/수/목/금)</span
                      >으로 수업 가능한 일자 순으로 배정이 진행됩니다. <br /><br />
                      ● 수업시작일 3월1일 기준 월,수,금 주3회 수업을 희망 할 경우, 3월4일, 3월6일, 3월8일이 희망 시작일로
                      정해지며,<br />
                      3월4일 시작일 기준 배정 가능한 강사가 없을 경우, 3월6일, 3월8일 순으로 배정가능강사 조회하여 가능한 일자를
                      기준으로 배정됩니다.
                      <br /><br />
                      자동배정까지 걸리는 시간은 <span class="font-bold underline underline-offset-1">약 1분 내외</span>로
                      <span class="font-bold">강사와 교재까지 자동배정</span> 됩니다. <br />(단, 수강 신청이 집중되는 시간에는
                      약간의 지연이 발생할 수 있음.) <br /><br />
                      또한, 배정 가능 강사가 없어 미배정 된 수강신청 건은
                      <span class="underline underline-offset-1">매일 04:00 AM</span> 재배정을 진행합니다. <br />(재배정은 영업일
                      기준 수업 시작 3일 전까지의 신청 건만 해당됨.) <br /><br />
                    </p>
                  </li>
                </ul>
                <p class="font-bold">
                  * 첫 수업 시작일은 강사 스케쥴에 따라 [교육기간]의 시작일로부터 최대 +7일 까지만 배정됩니다.
                  <br />
                  * 자동배정 진행사항은 [관리자단] - [신청자/대기인원] - [배정유무] 에서 확인하실 수 있습니다.
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <ul class="flex flex-wrap items-center gap-4 p-2 mb-5">
      <li>
        <select class="border border-solid rounded border-slate-800" v-model="listPage.searchParams.staffIdx">
          <option value="0">전체</option>
          <option v-for="(item, i) in listPage.staffList" :key="i" :value="item.idx">{{ item.ename }} ({{ item.kname }})</option>
        </select>
      </li>
      <li>
        <ul class="flex items-center gap-4">
          <li class="border border-solid rounded border-slate-800">
            <BagicDatePickerRange
              :eduStartDate="listPage.searchParams.eduStartDate"
              :eduEndDate="listPage.searchParams.eduEndDate"
              @date-click="listPage.setDate"
            />
          </li>
        </ul>
      </li>
      <li>
        <select class="border border-solid rounded border-slate-800" v-model="listPage.searchParams.status">
          <option value="0" selected>상태검색</option>
          <option value="processing">진행중</option>
          <option value="expected">예정</option>
          <option value="end">종료</option>
        </select>
      </li>
      <li>
        <select class="border border-solid rounded border-slate-800" v-model="listPage.searchParams.searchValue">
          <option value="company" selected>Company</option>
          <option value="class">과정명</option>
        </select>
      </li>
      <li>
        <div class="flex items-center gap-4">
          <input v-model="listPage.searchParams.searchKeyword" type="text" class="border border-solid rounded border-slate-800" />
          <div class="flex items-center">
            <p class="p-2 text-white bg-blue-600 border border-solid rounded cursor-pointer" @click="listPage.getClassList()">
              검색
            </p>
          </div>
        </div>
      </li>
    </ul>

    <BagicTable
      :header="listPage.tableHeader"
      :list="listPage.tableList"
      :pageSize="listPage.searchParams.pageSize"
      :totalRows="listPage.totalCnt"
      @paging-click="listPage.movePage"
      :detailRowName="listPage.detailRowName"
      :detailParamName="listPage.detailParamName"
    >
    </BagicTable>
  </div>
</template>
<script>
import { onMounted, reactive, watch } from "vue";
import { useRouter } from "vue-router";
import axios from "@lifeeduAdmin/plugins/axios.js";

import BagicTable from "@/lifeeduAdmin/components/BagicTable.vue";
import BagicDatePickerRange from "@/lifeeduAdmin/components/BagicDatePickerRange.vue";

export default {
  name: "Home",
  components: {
    BagicTable,
    BagicDatePickerRange,
  },
  setup() {
    const router = useRouter();

    const listPage = reactive({
      searchParams: {
        startDate: "",
        endDate: "",
        pageNum: 1,
        pageSize: 50,
        searchKeyword: "",
        searchValue: "company",
        staffIdx: 0,
        status: 0,
        idxLeSiteMain: "",
        eduStartDate: "",
        eduEndDate: "",
      },
      tableHeader: {
        rowNum: "No",
        companyName: "Company",
        sugangName: "과정명",
        classDate: "수강신청기간",
        hqmanger: "HQ 매니저",
        status: "상태",
        preview: "미리보기",
        applyWaitCount: "신청자/대기인원",
        currentSituation: "현황 Board",
        cancleCount: "취소자",
        notice: "공지사항",
      },
      tableList: [],
      staffList: [],
      detailRowName: "notice",
      detailParamName: "idxLeSiteMain",
      dateValue: "",
      totalCnt: 0,
      modalOnOff: false,

      // 리스트 조회 및 검색
      getClassList() {
        axios
          .get("/admin/sugangList", {
            params: listPage.searchParams,
          })
          .then(function (res) {
            if (res.data.success) {
              listPage.totalCnt = res.data.data.totalCnt;
              listPage.tableList = res.data.data.sugangList;
              if (Array.isArray(listPage.tableList)) {
                listPage.tableList.forEach(function (item) {
                  if (item.startDate === null || item.endDate === null) {
                    item.classDate = "미등록";
                  } else {
                    item.classDate = item.startDate + " ~ " + item.endDate;
                  }

                  if (item.sugangName === null) {
                    item.sugangName = "미등록";
                  }

                  // company
                  item.companyName = `<u><a href="/enrolmentStep1?idxLeSiteMain=${item.idxLeSiteMain}">${item.companyName}</a></u>`;

                  // 과정명
                  if (item.idxLeSugang === null) {
                    item.sugangName = `<u><a href="/enrolmentStep2?idxLeSiteMain=${item.idxLeSiteMain}">${item.sugangName}</a></u>`;
                  } else {
                    item.sugangName = `<u><a href="/enrolmentStep2?idxLeSugang=${item.idxLeSugang}">${item.sugangName}</a></u>`;
                  }

                  item.preview = "";
                  const isStage = window.location.href.includes("stage");
                  if (isStage) {
                    // 미리보기 (stage)
                    item.preview +=
                      "<u><a href='https://stage-lifeedu.carrotenglish.net/home?idxLeSiteMain=" +
                      item.idxLeSiteMain +
                      "' target='_blank'>" +
                      "[View]" +
                      "</a></u>";
                  } else {
                    // 미리보기 (master)
                    item.preview +=
                      "<u><a href='https://lifeedu.carrotapply.com/home?idxLeSiteMain=" +
                      item.idxLeSiteMain +
                      "' target='_blank'>" +
                      "[View]" +
                      "</a></u>";
                  }

                  // 신청자/대기인원
                  if (item.idxLeSugang == null) {
                  } else {
                    item.applyWaitCount = `<u><a href="/applicantList?idxLeSugang=${item.idxLeSugang}">${item.applyWaitCount}</a></u>`;
                  }

                  // 현황 Board
                  if (item.isGroup == "Y") {
                    item.currentSituation = "";
                    item.currentSituation +=
                      "<u><a href='/currentSituationBoard?idxLeSugang=" + item.idxLeSugang + "'>" + "[View]" + "</a></u>";
                  }

                  // 취소자
                  item.cancleCount = `<u><a href="/cancelerList?idxLeSugang=${item.idxLeSugang}">${item.cancleCount}</a></u>`;

                  // 공지사항 보기
                  item.notice = "";
                  item.notice +=
                    "<u><a href='/noticeManagement?idxLeSiteMain=" + item.idxLeSiteMain + "'>" + "[View]" + "</a></u>";
                });
              }
              listPage.searchParams.startDate = "";
              listPage.searchParams.endDate = "";
            } else {
              //alert(res.data.message);
            }
            // listPage.is_loader = false;
          });
      },

      // 스태프 조회
      getStaffList() {
        axios.get("/admin/staffList", {}).then(function (res) {
          if (res.data.success) {
            listPage.staffList = res.data.data.companyList;
          } else {
            //alert(res.data.message);
          }
          // listPage.is_loader = false;
        });
      },

      setDate(dateArr) {
        listPage.searchParams.startDate = dateArr[0];
        listPage.searchParams.endDate = dateArr[1];
      },

      movePage(value) {
        listPage.searchParams.pageNum = value;
        listPage.getClassList();
        listPage.getStaffList();
      },

      openModal() {
        listPage.modalOnOff = true;
      },

      closeModal() {
        listPage.modalOnOff = false;
      },
      // goToDetailPage(value) {
      //     let params = { idxLeSiteMain: value };
      //     router.push({ path: "/noticeManagement", query: params });
      // },
    });

    onMounted(() => {
      listPage.getClassList();
      listPage.getStaffList();
    });

    watch(
      () => listPage.dateValue,
      (cur, prev) => {
        cur = cur.replace(/ /gi, ""); //공백 제거
        const arr = cur.split("~");
        listPage.searchParams.startDate = arr[0];
        listPage.searchParams.endDate = arr[1];

        if (cur != prev) {
          console.log(listPage.startDate);
          console.log(listPage.endDate);
        }
      }
    );

    return {
      listPage,
    };
  },
};
</script>
<style></style>
