<template>
  <div>
    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <p class="hidden text-lg font-bold text-center pc:text-5xl text-neutral-950 mb-11 pc:block">회원가입</p>
      <div>
        <!-- <div class="mb-14 pc:mb-36" :style="signUser.userSignInfo.schoolLevel == '성인' ? 'margin-bottom:0px;' : ''"> -->
        <div class="mb-14 pc:mb-36">
          <p class="mb-5 text-lg font-bold pc:text-2xl text-neutral-950 pc:mb-4">회원 정보 : 기본정보</p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">회원 이름<br class="pc:hidden" />(실명)</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="text"
                  placeholder="회원 이름"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.kname"
                  @blur="signUser.validateKname"
                />
                <p class="text-xs pc:text-base text-neutral-300">* 실제로 수업 받을 학습자 성함을 기재해 주세요.</p>
              </div>
            </li>

            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">성별<br class="pc:hidden" /></p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5">
                <ul class="mb-1 flex gap-2">
                  <li class="flex items-center gap-1">
                    <input v-model="signUser.userSignInfo.gender" type="radio" name="gender" id="male" value="M" />
                    <label for="male" class="block">남</label>
                  </li>
                  <li class="flex items-center gap-1">
                    <input v-model="signUser.userSignInfo.gender" type="radio" name="gender" id="female" value="F" />
                    <label for="female" class="block">여</label>
                  </li>
                </ul>
                <p class="text-xs pc:text-base text-neutral-300">* 실제로 수업 받을 학습자 성별을 선택해 주세요.</p>
              </div>
            </li>

            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">이메일 주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5">
                <div class="flex flex-col gap-2.5">
                  <div class="flex flex-col gap-2.5 pc:flex-row">
                    <div class="flex items-center gap-2">
                      <input
                        type="text"
                        class="p-4 text-xs border border-solid rounded border-neutral-200 placeholder:text-neutral-200 pc:text-base pc:w-80"
                        v-model="signUser.userSignInfo.email"
                        @input="signUser.restrictToLowercase"
                        autocomplete="off"
                      />
                      <p>@</p>
                    </div>
                    <div class="flex items-center gap-2 pc:w-80">
                      <select
                        class="w-full py-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                        v-model="signUser.userSignInfo.selectEmail"
                      >
                        <option value="">이메일 선택</option>
                        <option value="naver.com">naver.com</option>
                        <option value="daum.net">daum.net</option>
                        <option value="hanmail.net">hanmail.net</option>
                        <option value="kakao.com">kakao.com</option>
                        <option value="gmail.com">gmail.com</option>
                        <option value="custom">직접입력</option>
                      </select>

                      <input
                        type="text"
                        placeholder="직접입력"
                        class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                        v-model="signUser.userSignInfo.inputEmail"
                        @input="signUser.restrictToLowercase"
                        v-if="signUser.userSignInfo.selectEmail === 'custom'"
                      />
                    </div>
                    <p
                      class="p-4 text-xs text-center border border-solid rounded cursor-pointer border-neutral-200 pc:text-base bg-neutral-50 text-neutral-500"
                      @click="signUser.getUserEmailCheck"
                    >
                      중복확인
                    </p>
                  </div>
                  <p v-if="signUser.emailCheck === 'available'" class="text-xs font-bold text-purple-600 pc:text-base">
                    사용 가능한 이메일입니다.
                  </p>
                  <p v-if="signUser.emailCheck === 'unavailable'" class="text-xs font-bold pc:text-base text-rose-600">
                    사용할 수 없는 이메일입니다.
                  </p>
                </div>
                <p class="flex-none text-xs pc:text-base text-neutral-300">
                  * 이메일 주소가 ID로 사용되오니 정확히 기재해주세요.
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">비밀번호</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="password"
                  placeholder="비밀번호"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.password"
                  @input="signUser.checkPasswordMatch"
                  autocomplete="new-password"
                />
                <p class="text-xs pc:text-base text-neutral-300">
                  * 영문 대/소문자, 숫자, 특수문자를 포함한 8자 이상 입력하세요.
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">비밀번호 확인</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <div class="flex flex-col gap-2.5">
                  <input
                    type="password"
                    placeholder="비밀번호"
                    class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                    v-model="signUser.userSignInfo.passwordCheck"
                    @input="signUser.checkPasswordMatch"
                  />
                  <p
                    class="text-xs font-bold pc:text-base"
                    :class="signUser.passwordMatch && signUser.isPasswordCheckValid ? 'text-purple-600' : 'text-rose-600'"
                    v-if="signUser.showPasswordMessage"
                  >
                    {{
                      signUser.passwordMatch
                        ? signUser.isPasswordCheckValid
                          ? "* 두 비밀번호가 동일합니다."
                          : "* 비밀번호가 유효하지 않습니다."
                        : "* 두 비밀번호가 다릅니다."
                    }}
                  </p>
                </div>
                <p class="text-xs pc:text-base text-neutral-300">
                  * 영문 대/소문자, 숫자, 특수문자를 포함한 8자 이상 입력하세요.
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">
                  영어이름<br class="pc:hidden" />(수업 이름)
                </p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="text"
                  placeholder="영어이름"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.ename"
                  @input="signUser.convertToLowerCase"
                />
                <p class="text-xs pc:text-base text-neutral-300">* 수업에서 사용되는 이름입니다.</p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="tel"
                  placeholder="010-1234-5678"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.mobile"
                  @input="signUser.formatMobile"
                />
                <ul>
                  <li class="text-xs pc:text-base text-neutral-300">
                    * 모든 안내문자와 연락에 사용되는 전화번호입니다. 정확히 기재해 주세요.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-300">
                    * 모든 안내를 학부모님 번호로 받기 희망하시면 학부모님 번호를 기재해 주세요.
                  </li>
                </ul>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">생년월일</p>
              </div>
              <div>
                <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                  <div class="flex gap-2.5 items-center">
                    <select
                      type="text"
                      class="w-32 p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.year"
                    >
                      <option v-for="year in signUser.userSignInfo.years" :key="year">{{ year }}</option>
                    </select>
                    <p>년</p>
                  </div>
                  <div class="flex gap-2.5 items-center">
                    <select
                      type="text"
                      class="w-32 p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.month"
                    >
                      <option v-for="month in signUser.userSignInfo.months" :key="month">{{ month }}</option>
                    </select>
                    <p>월</p>
                  </div>
                  <div class="flex gap-2.5 items-center">
                    <select
                      type="text"
                      class="w-32 p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.day"
                    >
                      <option v-for="day in signUser.userSignInfo.days" :key="day">{{ day }}</option>
                    </select>
                    <p>일</p>
                  </div>
                </div>
                <p class="pl-2.5 pb-3 pc:pl-9 pc:pb-4 text-xs pc:text-base text-neutral-300">
                  * 학습자의 생년월일을 입력해 주세요.
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">학교 레벨</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <select
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.schoolLevel"
                >
                  <option value="">선택해주세요</option>
                  <option value="미취학">미취학</option>
                  <option value="초등학교">초등학교</option>
                  <option value="중학교">중학교</option>
                  <option value="고등학교">고등학교</option>
                  <option value="성인">성인</option>
                </select>
              </div>
            </li>
            <li
              v-if="signUser.userSignInfo.schoolLevel !== '미취학' && signUser.userSignInfo.schoolLevel !== '성인'"
              class="flex items-center"
            >
              <div
                v-if="signUser.userSignInfo.schoolLevel !== ''"
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">학교/학년/반</p>
              </div>
              <div
                v-if="signUser.userSignInfo.schoolLevel === '초등학교'"
                class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center"
              >
                <ul class="flex flex-col pc:flex-row gap-2.5 pc:gap-9">
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="초등학교"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.schoolName"
                      @blur="signUser.validateSchoolName"
                    />
                    <p>초등학교</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="학년"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.schoolGrade"
                      @input="signUser.onInputNumberOnly"
                    />
                    <p>학년</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="반"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.schoolClass"
                      @input="signUser.onInputSchoolClassNumberOnly"
                    />
                    <p>반</p>
                  </li>
                </ul>
              </div>
              <div
                v-if="signUser.userSignInfo.schoolLevel === '중학교'"
                class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center"
              >
                <ul class="flex flex-col pc:flex-row gap-2.5 pc:gap-9">
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="중학교"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.schoolName"
                      @blur="signUser.validateSchoolName"
                    />
                    <p>중학교</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="학년"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.schoolGrade"
                      @input="signUser.onInputNumberOnly"
                    />
                    <p>학년</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="반"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.schoolClass"
                      @input="signUser.onInputSchoolClassNumberOnly"
                    />
                    <p>반</p>
                  </li>
                </ul>
              </div>
              <div
                v-if="signUser.userSignInfo.schoolLevel === '고등학교'"
                class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center"
              >
                <ul class="flex flex-col pc:flex-row gap-2.5 pc:gap-9">
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="고등학교"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200"
                      v-model="signUser.userSignInfo.schoolName"
                      @blur="signUser.validateSchoolName"
                    />
                    <p>고등학교</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="학년"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.schoolGrade"
                      @input="signUser.onInputNumberOnly"
                    />
                    <p>학년</p>
                  </li>
                  <li class="flex items-center gap-2.5">
                    <input
                      type="text"
                      placeholder="반"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-20"
                      v-model="signUser.userSignInfo.schoolClass"
                      @input="signUser.onInputSchoolClassNumberOnly"
                    />
                    <p>반</p>
                  </li>
                </ul>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <ul class="flex flex-col gap-2">
                  <li class="flex gap-2">
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="signUser.userSignInfo.postcode"
                      readonly
                      @click="signUser.execDaumPostcode"
                    />
                    <p
                      class="flex-none p-4 text-xs border border-solid rounded cursor-pointer pc:text-base text-neutral-500 bg-neutral-50 border-neutral-200"
                      @click="signUser.execDaumPostcode"
                    >
                      우편번호 찾기
                    </p>
                  </li>
                  <li>
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="signUser.userSignInfo.address"
                      readonly
                      @click="signUser.execDaumPostcode"
                    />
                  </li>
                  <li>
                    <input
                      type="text"
                      class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                      v-model="signUser.userSignInfo.addressSub"
                    />
                  </li>
                </ul>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">사회적배려<br class="pc:hidden" />대상자</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <label class="text-xs font-bold cursor-pointer pc:text-base check-label text-neutral-950"
                  >사회적배려대상자
                  <input
                    type="checkbox"
                    v-model="signUser.userSignInfo.socialConsideration"
                    true-value="Y"
                    false-value="N"
                    value=""
                  />
                  <div class="checkmark"></div>
                </label>
                <p class="text-xs pc:text-base text-neutral-500">
                  * 사회적배려대상자인 경우 체크해주시고, 자세한 사항은 시·도 기준을 참고해주세요.
                </p>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">
                  영어<br class="pc:hidden" />
                  레벨테스트<br class="pc:hidden" />
                  신청
                </p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <div class="flex gap-2.5 pc:gap-7">
                  <label class="text-xs font-bold cursor-pointer pc:text-base check-label text-neutral-950"
                    >신청함
                    <input type="radio" name="levelTest" v-model="signUser.userSignInfo.isLevelTest" value="Y" />
                    <div class="checkmark"></div>
                  </label>
                  <label class="text-xs font-bold cursor-pointer pc:text-base check-label text-neutral-950"
                    >신청 안함
                    <input type="radio" name="levelTest" v-model="signUser.userSignInfo.isLevelTest" value="N" />
                    <div class="checkmark"></div>
                  </label>
                </div>
                <ul>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 레벨테스트는 최초 1회 제공되며, 실제 수강신청을 해주시는 학습자분께 제공됩니다.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 학습 시작일 전, 기재해주신 학습자 핸드폰번호로 문자 안내되니 안내를 기다려주세요.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 레벨테스트를 받지 않으셔도, 수강신청 시 레벨 을 기재해주시면 맞춰서 배정해드립니다.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 영어 외 다른 언어는 담당매니저의 개별 안내를 기다려주세요.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="signUser.userSignInfo.schoolLevel !== '성인'" class="mb-7 pc:mb-24">
          <p class="mb-5 text-lg font-bold pc:text-2xl text-neutral-950 pc:mb-4">
            학부모 정보
            <span class="text-orange-600">(※주니어 학습자인 경우에만 입력해주세요.)</span>
          </p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">학부모 이름</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="text"
                  placeholder="학부모 이름"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.parentName"
                  @blur="signUser.validatePname"
                />
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">이메일 주소</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <div class="flex flex-col gap-2.5">
                  <div class="flex flex-col gap-2.5 pc:flex-row">
                    <div class="flex items-center gap-2">
                      <input
                        type="text"
                        class="p-4 text-xs border border-solid rounded border-neutral-200 placeholder:text-neutral-200 pc:text-base pc:w-80"
                        v-model="signUser.userSignInfo.parentEmail"
                        @input="signUser.restrictToLowercase"
                      />
                      <p>@</p>
                    </div>
                    <div class="flex items-center gap-2 pc:w-80">
                      <select
                        class="w-full py-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                        v-model="signUser.userSignInfo.parentSelectEmail"
                      >
                        <option value="">이메일 선택</option>
                        <option value="naver.com">naver.com</option>
                        <option value="daum.net">daum.net</option>
                        <option value="hanmail.net">hanmail.net</option>
                        <option value="kakao.com">kakao.com</option>
                        <option value="gmail.com">gmail.com</option>
                        <option value="custom">직접입력</option>
                      </select>
                      <input
                        type="text"
                        placeholder="직접입력"
                        class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                        v-model="signUser.userSignInfo.parentinputEmail"
                        @input="signUser.restrictToLowercase"
                        v-if="signUser.userSignInfo.parentSelectEmail === 'custom'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">휴대전화</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5 pc:flex-row pc:gap-9 pc:items-center">
                <input
                  type="tel"
                  placeholder="010-1234-5678"
                  class="p-4 text-xs border border-solid rounded pc:text-base border-neutral-200 placeholder:text-neutral-200 pc:w-80"
                  v-model="signUser.userSignInfo.parentMobile"
                  @input="signUser.formatParentMobile"
                />
                <ul>
                  <li class="text-xs pc:text-base text-neutral-300">
                    * 모든 안내문자와 연락에 사용되는 전화번호입니다. 정확히 기재해 주세요.
                  </li>
                  <li class="text-xs pc:text-base text-neutral-300">
                    * 모든 안내를 학부모님 번호로 받기 희망하시면 학부모님 번호를 기재해 주세요.
                  </li>
                </ul>
              </div>
            </li>
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">환불 계좌</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5">
                <ul class="flex flex-col gap-2 pc:flex-row pc:items-center">
                  <li class="flex items-center gap-2 pc:w-80">
                    <select
                      class="w-full py-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                      v-model="signUser.userSignInfo.parentBank"
                    >
                      <option value="">계좌 선택</option>
                      <option value="KB국민은행">KB국민은행</option>
                      <option value="신한은행">신한은행</option>
                      <option value="우리은행">우리은행</option>
                      <option value="하나은행">하나은행</option>
                      <option value="SC제일은행">SC제일은행</option>
                      <option value="한국씨티은행">한국씨티은행</option>
                      <option value="카카오뱅크">카카오뱅크</option>
                      <option value="대구은행">대구은행</option>
                      <option value="부산은행">부산은행</option>
                      <option value="경남은행">경남은행</option>
                      <option value="광주은행">광주은행</option>
                      <option value="전북은행">전북은행</option>
                      <option value="제주은행">제주은행</option>
                      <option value="SBI저축은행">SBI저축은행</option>
                      <option value="애큐온저축은행">애큐온저축은행</option>
                      <option value="키움YES저축은행">키움YES저축은행</option>
                      <option value="푸른저축은행">푸른저축은행</option>
                      <option value="OK저축은행">OK저축은행</option>
                      <option value="웰컴저축은행">웰컴저축은행</option>
                      <option value="페퍼저축은행">페퍼저축은행</option>
                      <option value="NH저축은행">NH저축은행</option>
                      <option value="KB저축은행">KB저축은행</option>
                      <option value="NH농협은행">NH농협은행</option>
                    </select>

                    <input
                      type="text"
                      class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base placeholder:text-neutral-200"
                      placeholder="예금주"
                      v-model="signUser.userSignInfo.parentBankAccountHolder"
                    />
                  </li>
                  <li class="grow pc:grow-0">
                    <input
                      type="text"
                      class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base placeholder:text-neutral-200"
                      placeholder="계좌번호"
                      maxlength="100"
                      v-model="signUser.userSignInfo.parentBankNumber"
                      @input="signUser.formatParentBankNumber()"
                    />
                  </li>
                </ul>
                <ul>
                  <li class="text-xs pc:text-base text-neutral-500">* '-' 와 띄어쓰기 없이 숫자만 입력해 주세요.</li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 수강료와 교재비 환불이 진행될 계좌이니 정확히 기재해주세요.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <!-- 성인 환불 계좌 -->
        <div v-if="signUser.userSignInfo.schoolLevel == '성인'" class="mb-7 pc:mb-24">
          <p class="mb-5 text-lg font-bold pc:text-2xl text-neutral-950 pc:mb-4">
            환불계좌 정보
            <span class="text-orange-600">(※성인 학습자인 경우에만 입력해주세요.)</span>
          </p>
          <ul class="grid grid-cols-1 border-t border-b border-solid divide-y border-neutral-950 divide-solid divide-neutral-200">
            <li class="flex items-center">
              <div
                class="flex-none w-24 pc:w-60 px-2.5 py-3 pc:px-9 pc:py-4 self-stretch flex justify-center items-center bg-neutral-50"
              >
                <p class="text-sm font-bold text-center text-neutral-950 pc:text-lg">환불 계좌</p>
              </div>
              <div class="grow pl-2.5 py-3 pc:pl-9 pc:py-4 flex flex-col gap-2.5">
                <ul class="flex flex-col gap-2 pc:flex-row pc:items-center">
                  <li class="flex items-center gap-2 pc:w-80">
                    <select
                      class="w-full py-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base"
                      v-model="signUser.userSignInfo.parentBank"
                    >
                      <option value="">계좌 선택</option>
                      <option value="KB국민은행">KB국민은행</option>
                      <option value="신한은행">신한은행</option>
                      <option value="우리은행">우리은행</option>
                      <option value="하나은행">하나은행</option>
                      <option value="SC제일은행">SC제일은행</option>
                      <option value="한국씨티은행">한국씨티은행</option>
                      <option value="카카오뱅크">카카오뱅크</option>
                      <option value="대구은행">대구은행</option>
                      <option value="부산은행">부산은행</option>
                      <option value="경남은행">경남은행</option>
                      <option value="광주은행">광주은행</option>
                      <option value="전북은행">전북은행</option>
                      <option value="제주은행">제주은행</option>
                      <option value="SBI저축은행">SBI저축은행</option>
                      <option value="애큐온저축은행">애큐온저축은행</option>
                      <option value="키움YES저축은행">키움YES저축은행</option>
                      <option value="푸른저축은행">푸른저축은행</option>
                      <option value="OK저축은행">OK저축은행</option>
                      <option value="웰컴저축은행">웰컴저축은행</option>
                      <option value="페퍼저축은행">페퍼저축은행</option>
                      <option value="NH저축은행">NH저축은행</option>
                      <option value="KB저축은행">KB저축은행</option>
                      <option value="NH농협은행">NH농협은행</option>
                    </select>
                    <input
                      type="text"
                      class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base placeholder:text-neutral-200"
                      placeholder="예금주"
                      v-model="signUser.userSignInfo.parentBankAccountHolder"
                    />
                  </li>
                  <li class="grow pc:grow-0">
                    <input
                      type="text"
                      class="w-full p-4 text-xs border border-solid rounded cursor-pointer border-neutral-200 pc:text-base placeholder:text-neutral-200"
                      placeholder="계좌번호"
                      maxlength="100"
                      v-model="signUser.userSignInfo.parentBankNumber"
                      @input="signUser.formatParentBankNumber()"
                    />
                  </li>
                </ul>
                <ul>
                  <li class="text-xs pc:text-base text-neutral-500">* '-' 와 띄어쓰기 없이 숫자만 입력해 주세요.</li>
                  <li class="text-xs pc:text-base text-neutral-500">
                    * 수강료와 교재비 환불이 진행될 계좌이니 정확히 기재해주세요.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <p
          class="max-w-xs py-4 mx-auto text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl"
          @click="signUser.signRegister"
        >
          회원가입
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@lifeeduWeb/plugins/axios.js";
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain;
    const idxCompany = store.getters.getIdxCompany;

    console.log("idxCompany", idxCompany);

    const signUser = reactive({
      userSignInfo: {
        address: "", // 주소
        addressSub: "", // 상세 주소
        birthday: computed(() => `${signUser.userSignInfo.year}${signUser.userSignInfo.month}${signUser.userSignInfo.day}`),
        ename: "", // 영어 이름
        email: "", // 이메일
        selectEmail: "",
        inputEmail: "",
        idxMember: "",
        isLevelTest: "", // 레벨 테스트 여부
        kname: "", // 한글 이름
        mobile: "", // 휴대폰 번호
        parentBank: "", // 부모님 환불계좌 은행
        parentBankAccountHolder: "", // 부모님 환불계좌 예금주
        parentBankNumber: "", // 부모님 환불계좌 계좌번호
        parentEmail: "", // 부모님 이메일
        parentinputEmail: "",
        parentSelectEmail: "",
        parentMobile: "", // 부모님 휴대폰 번호
        parentName: "", // 부모님 이름
        password: "", // 비밀번호
        passwordCheck: "", // 비밀번호 확인
        postcode: "", // 우편번호
        schoolLevel: "", // 학교 레벨
        socialConsideration: "N", // 사회적 배려 여부
        year: "",
        month: "",
        day: "",
        schoolName: "",
        schoolGrade: "",
        schoolClass: "",
        years: Array.from({ length: 75 }, (_, i) => 1950 + i),
        months: Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, "0")),
        days: Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, "0")),
        gender: "", // 성별
      },

      passwordMatch: false,
      showPasswordMessage: false,

      emailCheck: null,

      isPasswordCheckValid: false,

      onInputNumberOnly(event) {
        // 숫자 이외의 값 제거
        let value = event.target.value.replace(/[^1-9]/g, "");
        const intValue = parseInt(value, 10);
        if (intValue >= 1 && intValue <= 9) {
          event.target.value = intValue.toString();
        } else {
          event.target.value = "";
        }
      },

      onInputSchoolClassNumberOnly(event) {
        event.target.value = event.target.value.replace(/[^가-힣a-zA-Z0-9]/g, "");
      },

      // restrictInput() {
      //   signUser.userSignInfo.schoolName = signUser.userSignInfo.schoolName.replace(/[^\w가-힣]/g, "");
      // },

      validateSchoolName() {
        const koreanRegex = /^[가-힣]+$/;
        const originalName = signUser.userSignInfo.schoolName; // 원래의 학교 이름 저장

        if (!koreanRegex.test(originalName)) {
          const filteredName = originalName.replace(/[^가-힣]/g, "");
          signUser.userSignInfo.schoolName = filteredName; // 필터링된 이름을 저장

          // 원래 이름과 필터링된 이름이 다르면 경고창 표시
          if (originalName !== filteredName) {
            alert("한글만 입력해주세요.");
          }
        }
      },
      // validateKoreanInput() {
      //   const koreanRegex = /^[가-힣]*$/;

      //   if (!koreanRegex.test(signUser.userSignInfo.kname) || /\s/.test(signUser.userSignInfo.kname)) {
      //     alert("한글만 입력해주세요.");
      //     signUser.userSignInfo.kname = signUser.userSignInfo.kname.replace(/[^가-힣]/g, "");
      //   }

      //   if (!koreanRegex.test(signUser.userSignInfo.kname)) {
      //     signUser.userSignInfo.kname = signUser.userSignInfo.kname.replace(/[^가-힣]/g, "");

      //     alert("한글만 입력해주세요.");
      //   }
      // },

      validateKname() {
        const koreanRegex = /^[가-힣]+$/;

        if (!koreanRegex.test(signUser.userSignInfo.kname)) {
          signUser.userSignInfo.kname = signUser.userSignInfo.kname.replace(/[^가-힣]/g, "");

          alert("한글만 입력해주세요.");
        }
      },

      validatePname() {
        const koreanRegex = /^[가-힣]+$/;

        if (!koreanRegex.test(signUser.userSignInfo.parentName)) {
          signUser.userSignInfo.parentName = signUser.userSignInfo.parentName.replace(/[^가-힣]/g, "");

          alert("한글만 입력해주세요.");
        }
      },

      restrictToLowercase() {
        signUser.userSignInfo.email = signUser.userSignInfo.email.toLowerCase().replace(/[^a-zA-Z0-9\-._]/g, "");
        signUser.userSignInfo.inputEmail = signUser.userSignInfo.inputEmail.toLowerCase().replace(/[^a-z0-9.]/g, "");
        signUser.userSignInfo.parentEmail = signUser.userSignInfo.parentEmail.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
        signUser.userSignInfo.parentinputEmail = signUser.userSignInfo.parentinputEmail.toLowerCase().replace(/[^a-z0-9.]/g, "");
      },

      checkPasswordMatch() {
        signUser.passwordMatch = signUser.userSignInfo.password === signUser.userSignInfo.passwordCheck;

        const passwordRegex = /^(?=.*\d)(?=.*[~`!@#$%\^&*()-])(?=.*[a-zA-Z]).{8,15}$/;
        const isPasswordCheckValid = passwordRegex.test(signUser.userSignInfo.passwordCheck);

        signUser.showPasswordMessage =
          signUser.userSignInfo.password.length > 0 || signUser.userSignInfo.passwordCheck.length > 0;
        this.isPasswordCheckValid = isPasswordCheckValid;
      },

      convertToLowerCase() {
        const input = signUser.userSignInfo.ename;

        if (/[ㄱ-ㅎㅏ-ㅣ가-힣\d]/.test(input)) {
          alert("영어 이름은 영어만 입력 가능합니다.");
          signUser.userSignInfo.ename = input.replace(/[^a-zA-Z]/g, "");
        } else {
          signUser.userSignInfo.ename = input.toLowerCase();
        }
      },

      formatMobile() {
        let input = signUser.userSignInfo.mobile;

        // 정규식을 사용하여 숫자만 추출
        let numericValue = input.replace(/\D/g, "");

        // 하이픈 추가
        let formattedValue = numericValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");

        // 최대 길이를 초과하지 않도록 조정
        signUser.userSignInfo.mobile = formattedValue.slice(0, 13);
      },

      formatParentMobile() {
        let input = signUser.userSignInfo.parentMobile;
        // 정규식을 사용하여 숫자만 추출
        let numericValue = input.replace(/\D/g, "");
        // 하이픈 추가
        let formattedValue = numericValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        // 최대 길이를 초과하지 않도록 조정
        signUser.userSignInfo.parentMobile = formattedValue.slice(0, 13);
      },

      formatParentBankNumber() {
        signUser.userSignInfo.parentBankNumber = signUser.userSignInfo.parentBankNumber.replace(/\D/g, "");
      },

      execDaumPostcode() {
        new window.daum.Postcode({
          oncomplete: (data) => {
            signUser.userSignInfo.postcode = data.zonecode;
            let buildingName = data.buildingName ? ` (${data.buildingName})` : "";
            signUser.userSignInfo.address = data.roadAddress + buildingName;
            // signUser.userSignInfo.addressSub = data.jibunAddress;
          },
        }).open();
      },

      signRegister() {
        if (!signUser.userSignInfo.kname) {
          alert("회원 이름을 입력해주세요.");
          return;
        }

        if (!signUser.userSignInfo.gender) {
          alert("성별을 선택해주세요.");
          return;
        }

        if (!signUser.userSignInfo.email) {
          alert("이메일을 입력해주세요.");
          return;
        }

        if (!signUser.userSignInfo.selectEmail) {
          alert("이메일을 확인해주세요.");
          return;
        }

        if (!signUser.emailCheck || signUser.emailCheck === "unavailable") {
          alert("이메일 중복 확인을 먼저 진행해주세요.");
          return;
        }

        if (!signUser.userSignInfo.password) {
          alert("비밀번호를 입력해주세요.");
          return;
        }

        if (!signUser.userSignInfo.passwordCheck) {
          alert("비밀번호를 확인해주세요.");
          return;
        }

        if (!signUser.userSignInfo.ename) {
          alert("영어이름을 입력해주세요");
          return;
        }

        if (!signUser.userSignInfo.mobile) {
          alert("휴대전화 번호를 입력해주세요.");
          return;
        }

        if (!signUser.userSignInfo.birthday) {
          alert("생년월일을 입력해주세요.");
          return;
        }

        if (!signUser.userSignInfo.schoolLevel) {
          alert("학교 레벨을 선택해주세요");
          return;
        }

        // if (
        //   signUser.userSignInfo.schoolLevel !== "미취학" &&
        //   signUser.userSignInfo.schoolLevel !== "성인" &&
        //   !signUser.userSignInfo.schoolInfo
        // ) {
        //   alert("학교정보를 입력해주세요.");
        //   return;
        // }

        if (!signUser.userSignInfo.postcode && !signUser.userSignInfo.address && !signUser.userSignInfo.addressSub) {
          alert("주소를 입력해주세요.");
          return;
        }

        if (!signUser.userSignInfo.isLevelTest) {
          alert("레벨 테스트 신청 여부를 선택해주세요.");
          return;
        }

        const mobileRegex = /^010-\d{3,4}-\d{4}$/;
        if (!mobileRegex.test(signUser.userSignInfo.mobile)) {
          alert("휴대폰 번호를 다시 확인해주세요.");
          return;
        }

        const params = {
          address: signUser.userSignInfo.address,
          addressSub: signUser.userSignInfo.addressSub,
          birthday: signUser.userSignInfo.birthday,
          email:
            signUser.userSignInfo.selectEmail === "custom"
              ? `${signUser.userSignInfo.email}@${signUser.userSignInfo.inputEmail}`
              : `${signUser.userSignInfo.email}@${signUser.userSignInfo.selectEmail}`,
          ename: signUser.userSignInfo.ename,
          idxMember: signUser.userSignInfo.idxMember,
          isLevelTest: signUser.userSignInfo.isLevelTest,
          kname: signUser.userSignInfo.kname,
          mobile: signUser.userSignInfo.mobile,
          parentBank: signUser.userSignInfo.parentBank,
          parentBankAccountHolder: signUser.userSignInfo.parentBankAccountHolder,
          parentBankNumber: signUser.userSignInfo.parentBankNumber,
          parentEmail:
            signUser.userSignInfo.parentSelectEmail === "custom"
              ? `${signUser.userSignInfo.parentEmail}@${signUser.userSignInfo.parentinputEmail}`
              : `${signUser.userSignInfo.parentEmail}@${signUser.userSignInfo.parentSelectEmail}`,
          parentMobile: signUser.userSignInfo.parentMobile,
          parentName: signUser.userSignInfo.parentName,
          password: signUser.userSignInfo.password,
          passwordCheck: signUser.userSignInfo.passwordCheck,
          postcode: signUser.userSignInfo.postcode,
          schoolLevel: signUser.userSignInfo.schoolLevel,
          socialConsideration: signUser.userSignInfo.socialConsideration,
          schoolName: signUser.userSignInfo.schoolName,
          schoolGrade: signUser.userSignInfo.schoolGrade,
          schoolClass: signUser.userSignInfo.schoolClass,
          idxCompany: idxCompany,
          gender: signUser.userSignInfo.gender,
        };
        axios.post(`/user/auth/register`, params, {}).then(function (res) {
          if (res.data.success === true) {
            router.push({
              path: "/signUp/signUpOk",
            });
          } else {
            alert(res.data.message);
          }
        });
      },

      getUserEmailCheck() {
        const email =
          signUser.userSignInfo.selectEmail === "custom"
            ? `${signUser.userSignInfo.email}@${signUser.userSignInfo.inputEmail}`
            : `${signUser.userSignInfo.email}@${signUser.userSignInfo.selectEmail}`;

        axios.get(`/user/auth/getUserEmailCheck`, { params: { email } }).then(function (res) {
          if (res.data.success === true) {
            signUser.emailCheck = "available";
          } else {
            signUser.emailCheck = "unavailable";
          }
        });
      },
    });

    onMounted(() => {});

    return {
      signUser,
      idxLeSiteMain,
      idxCompany,
    };
  },
};
</script>
<style scoped>
input[type="radio"] {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
</style>
