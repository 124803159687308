<template>
  <footer v-if="idxLeSiteMain != '52'"  id="footer" class="bg-neutral-800">
    <div class="flex flex-col gap-4 py-12 pc-con-wrapper1">
      <p class="font-medium text-neutral-200">당근영어 원격평생교육시설</p>
      <ul class="flex flex-col gap-1">
        <li class="text-[11px] pc:text-sm font-light text-neutral-500">
          (주)캐럿솔루션즈 | 서울특별시 서초구 강남대로 479 신논현타워 6F 06541 | 대표이사 이은경<br class="pc:hidden" />
          Copyright ⓒCARROT Solutions Inc. All rights reserved.
          <span class="cursor-pointer" @click="goSite('https://www.carrotenglish.kr/phone/privacy')">개인정보처리방침</span
          >ㆍ<span class="cursor-pointer" @click="goSite('https://www.carrotenglish.kr/phone/terms')">회원약관</span>
        </li>
        <li class="text-[11px] pc:text-sm font-light text-neutral-500">
          사업자 등록번호 357-87-02040 | 통신판매업 신고번호 제 2021-서울강남-05384호 | 대표번호 : 02-518-0036
        </li>
        <li class="text-[11px] pc:text-sm font-light text-neutral-500">
          이메일 helpcarrot@carrotglobal.com | 호스팅제공자 : (주)엘지유플러스 KIDC | 개인정보관리책임자 김범진
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import { useStore } from "vuex";


export default {
  name: "index",
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    let idxLeSiteMain = store.getters.getIdxLeSiteMain;
    const goSite = (url) => {
      window.open(url, "_blank");
    };

    onMounted(() => {});
    return {
      goSite,
      idxLeSiteMain
    };
  },
};
</script>
<style scoped></style>
