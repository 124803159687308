<template>
  <div>
    <section class="pt-[55px] pb-[70px] pc:py-[140px] pc-con-wrapper1">
      <p class="hidden text-lg font-bold text-center pc:text-5xl text-neutral-950 mb-11 pc:block">회원가입</p>
      <div class="px-5 border border-solid py-7 pc:py-11 border-neutral-200 rounded-2xl">
        <div>
          <p class="text-lg font-bold text-center text-neutral-950 mb-7 pc:text-2xl pc:mb-16">약관동의</p>
          <div class="mb-14 pc:mb-11">
            <p class="mb-4 text-sm font-medium text-neutral-950 pc:text-lg">[필수] 이용약관</p>
            <div
              class="p-5 overflow-y-auto text-xs border border-solid rounded pc:text-base h-60 pc:h-64 border-neutral-200 text-neutral-500 mb-2.5 whitespace-pre-line"
            >
              {{ signUser.termsUse }}
            </div>
            <div>
              <label class="text-xs font-bold cursor-pointer pc:text-base check-label text-neutral-950"
                >위 이용약관의 내용에 동의합니다.
                <input type="checkbox" v-model="signUser.termsOfUseCheckbox" @change="signUser.checkAllAgreements" />
                <div class="checkmark"></div>
              </label>
            </div>
          </div>
          <div class="mb-7 pc:mb-11">
            <p class="mb-4 text-sm font-medium text-neutral-950 pc:text-lg">[필수] 개인정보 수집 및 이용</p>
            <div
              class="p-5 overflow-y-auto text-xs border border-solid rounded pc:text-base h-60 pc:h-64 border-neutral-200 text-neutral-500 mb-2.5 whitespace-pre-line"
            >
              {{ signUser.Privacy }}
            </div>
            <div>
              <label class="text-xs font-bold cursor-pointer pc:text-base check-label text-neutral-950"
                >위 이용약관의 내용에 동의합니다.
                <input type="checkbox" v-model="signUser.privacyCheckbox" @change="signUser.checkAllAgreements" />
                <div class="checkmark"></div>
              </label>
            </div>
          </div>
          <div class="mb-7 pc:mb-11">
            <label class="text-xs font-light cursor-pointer pc:text-base check-label text-neutral-950"
              >전체 동의하기
              <input type="checkbox" v-model="signUser.allChecked" @change="signUser.allCheckBtn" />
              <div class="checkmark"></div>
            </label>
          </div>
          <ul class="grid grid-cols-2 gap-2.5 pc:gap-5 pc:max-w-md mx-auto max-w-xs">
            <li
              class="py-4 text-sm font-bold text-center border border-solid rounded cursor-pointer text-neutral-500 pc:py-6 pc:text-xl border-neutral-500"
              @click="signUser.backHome()"
            >
              돌아가기
            </li>
            <li
              class="py-4 text-sm font-bold text-center text-white bg-orange-500 rounded cursor-pointer pc:py-6 pc:text-xl"
              @click="signUser.nextSignPage"
            >
              다음
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const idxLeSiteMain = store.getters.getIdxLeSiteMain;

    const signUser = reactive({
      termsOfUseCheckbox: false,
      privacyCheckbox: false,
      allChecked: false,

      termsUse: `
            제 1 조(목적)
            이 약관은 ㈜캐럿솔루션즈(이하 당근영어라 칭함)에서 제공하는 영어교육 서비스를 이용함에 있어 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.

            제 2 조(정의)
            ① 이용자 : 이용자란 웹사이트에 접속하여 이 약관에 따라 당근영어가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
            ② 회원 : 회원이란 당근영어에 개인정보를 제공하여 회원등록을 한 자로서, 당근영어의 서비스를 지속적으로 제공받으며, 당근영어가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
            ③ 비회원 : 비회원이라 함은 회원에 가입하지 않고 당근영어가 제공하는 서비스를 이용하는 자를 말합니다.
            ④ 수강신청 : 수강신청이란 회원이 당근영어에서 제공하는 온라인 유/무료 서비스를 이용하기 위하여 당근영어가 정한 별도의 기준 및 절차에 따라 이용을 신청하는 것을 의미합니다.
            ⑤ 수강료 : 당근영어가 제공하는 유료강의를 이용하기 위한 대가로 당근영어가 정한 별도의 요금체계에 의해 특정기간 단위로 부과되는 금액을 의미합니다.
            ⑥ 수강승인 : 회원이 당근영어에게 수강신청을 요구하고 당근영어가 유/무료강의 이용을 승인하는 것을 의미합니다.

            제 3 조(약관의 명시와 개정)
            ① 당근영어는 이 약관의 내용과 상호, 영업소, 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 당근영어 웹사이트에 게시합니다.
            ② 당근영어는 약관의 구제등에 관한 법률, 전자거래기본법, 전자 서명 법, 정보통신망이용촉진등에 관한 법률, 방문판매등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
            ③ 당근영어가 이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 당근영어 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
            ④ 당근영어가 이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정 약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 당근영어에 송신하여 당근영어의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
            ⑤ "회원"은 변경된 약관이 공지된 후 15일 이내에 변경된 약관에 대한 거부의사를 표명할 수 있으며 "회원"이 거부의사를 표명할 경우 당근영어는 "회원"과의 계약을 해지할 수 있습니다. 만약"회원"이 변경된 약관이 공지된 후 15일 이내에 거부의사를 표시하지 않는 경우에는 변경된 약관에 동의하는 것으로 간주합니다.
            ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.

            제 4 조(서비스의 제공 및 변경)
            ① 당근영어는 다음과 같은 업무를 수행합니다.
            1. 학습정보 제공과 유/무료 서비스 정보제공 및 구매계약의 체결
            2. 구매계약이 체결된 온라인 유/무료 서비스 제공
            3. 기타 당근영어가 정하는 업무
            ② 당근영어는 당근영어가 제공하는 서비스 및 기술적 사양 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 서비스 내용을 변경할 수 있습니다. 이 경우에는 변경된 서비스의 내용 및 제공일자를 명시하여 현재의 재화•및 용역의 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다.
            ③ 당근영어가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 당근영어의 내부적인 사유로 변경할 경우에는 당근영어는 이로 인하여 이용자가 입은 손해를 배상합니다. 단, 당근영어의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.

            제 5 조(서비스의 중단)
            ① 당근영어는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
            ② 제 1항에 의한 서비스 중단의 경우에는 당근영어는 제 8조에 정한 방법으로 이용자에게 통지합니다.
            ③ 당근영어는 제 1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제 3자가 입은 손해에 대하여 배상합니다. 단, 당근영어의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.

            제 6 조(회원가입)
            ① 이용자는 이 약관에 동의한다는 의사표시를 한 후 당근영어가 정한 가입 양식에 따라 회원정보를 기입함으로써 회원가입을 신청합니다.
            ② 당근영어는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 온라인 및 모바일 회원으로 등록합니다.
            1. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 당근영어의 회원 재가입 승낙을 얻은 경우에는 예외로 한다.
            2. 등록내용에 허위, 기재누락, 오기가 있는 경우
            3. 기타 회원으로 등록하는 것이 당근영어의 기술상 현저한 지장이 있다고 판단되는 경우
            ③ 회원가입계약의 성립시기는 당근영어의 승낙이 회원에게 도달한 시점으로 합니다.
            ④ 회원은 제 15조 제 1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편, 기타 방법으로 당근영어에 그 변경사항을 알려야 합니다.

            제 7 조(회원탈퇴 및 자격 상실 등)
            ① 회원은 당근영어에 언제든지 탈퇴를 요청할 수 있으며 당근영어는 즉시 회원탈퇴를 처리합니다.
            ② 회원이 다음 각 호의 사유에 해당하는 경우, 당근영어는 회원자격을 제한 및 정지시킬 수 있습니다.
            1. 가입 신청 시에 허위 내용을 등록한 경우
            2. 당근영어를 이용하여 구입한 재화, 용역 등의 대금, 기타 당근영어 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
            3. 다른 사람의 당근영어 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
            4. 당근영어를 이용하여 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
            ③ 당근영어가 회원자격을 제한, 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 당근영어는 회원자격을 상실 시킬 수 있습니다.
            ④ 당근영어가 회원자격을 상실 시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.

            제 8 조(회원에 대한 통지)
            ① 당근영어는 회원에 대한 통지를 하는 경우, 회원이 당근영어에 제출한 전자우편 주소 및 SMS로 할 수 있습니다.
            ② 당근영어는 불특정다수 회원에 대한 통지의 경우 1주일이상 당근영어 게시판에 게시 함으로서 개별 통지에 갈음할 수 있습니다.

            제 9 조(수강신청)
            ① 회원은 당근영어가 지정한 수강신청서를 작성, 제출함으로써 온라인 유/무료 강의를 수강신청 할 수 있습니다.
            ② 당근영어는 수강신청에 대하여 다음 각 호에 해당하지 않는 한 수강신청을 승낙합니다.
            1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
            2. 수강신청에 승낙하는 것이 당근영어 기술상 현저히 지장이 있다고 판단하는 경우

            제 10 조(수강료의 납부)
            ① 회원은 당근영어가 제공하는 온라인 유료강의를 수강하기 위해서는 지정된 수강료를 지불해야만 합니다.
            ② 수강료는 당근영어가 지정한 요금체계에 의하며 당근영어에 공지한 가격을 기준으로 합니다.
            ③ 수강료 지급방법은 다음 각 호의 하나로 할 수 있습니다.
            1. 신용카드 결제
            2. 무통장 입금
            3. 온라인 계좌이체
            4. 네이버 페이
            5. 카카오 페이

            제 11 조(수신확인통지, 수강신청 변경 및 취소)
            ① 당근영어는 회원의 수강신청이 있는 경우 회원에게 수신확인 통지를 합니다.
            ② 수신확인통지를 받은 회원은 의사표시의 불일치 등이 있는 경우에는 수신확인 통지를 받은 후 즉시 수강신청 변경 및 취소를 요청 할 수 있습니다.
            ③ 당근영어는 수강승인 전 회원의 수강신청 변경 및 취소 요청이 있는 때에는 지체 없이 그 요청에 따라 처리합니다.

            제 12 조(수강승인)
            ① 당근영어는 회원으로부터 지정된 수강료 입금이 확인되는 즉시, 수강료를 지불한 유료강의에 대한 수강을 승인합니다.
            ② 당근영어는 회원이 신용카드로 수강료를 지급할 경우, 결제완료와 동시에 수강료를 지불한 유료강의에 대한 수강을 즉시 승인합니다.
            ③ 당근영어는 회원이 무통장 입금으로 수강료를 지급할 경우, 수강료 입금확인과 동시에 수강료를 지불한 유료강의에 대한 수강을 즉시 승인합니다.
            ④ 당근영어는 회원의 수강신청에 따른 승인이 이루어졌을 경우, 이를 회원에게 통지합니다.
            ⑤ 수강이 승인된 정해진 관리system 의해서 학습을 시작할 수 있으며, 지정된 학습기간이 종료된 후에는 별도의 재학습 기간을 제공해 드리지 않고 있습니다.

            제 13 조(수강취소 및 환불)
            환불의 규정은 아래와 같으며 이는 평생교육 사업법에 의거하여 규정되었습니다.
            ① 전화강의 서비스 이용료 징수기간이 1개월 이내인 경우
            1) 수업시작 전 - 기납입한 이용료 전액
            2) 총 수업시간의 1/3이 경과하기 전 - 기납입한 이용료의 2/3에 해당되는 금액
            3) 총 수업시간의 1/2이 경과하기 전 - 기납입한 이용료의 1/2에 해당되는 금액
            4) 총 수업시간의 1/2이 경과한 후 - 반환하지 아니함
            ② 전화강의 서비스 이용료 징수기간이 1개월을 초과하는 경우
            1) 수업시작 전 - 기납입한 이용료 전액
            2) 수업시작 후 - 환불사유가 발생한 그 달의 환불대상 이용료
            (이용료 징수기간이 1개월 이내인 경우에 준하여 산출된 이용료)와 나머지 달의 이용료 전액을 합산한 금액 )
            단, 장기수강에 따른 할인율이 적용된 경우, 환불사유 적용시점에서 수업이 완료된 개월에 대해 할인액을 재적용하여 환불전 적용된 할인액과의 차액을 환불금액에서 차감합니다. 단, 재적용되는 할인액은 신청한 상품의 수강신청페이지상에서 완료된 개월에 준한 할인율을 기준으로 합니다.
            환불금액은 환불 신청 후 일주일 내에 고객님이 요청한 통장으로 입금이 되며, 카드로 결제한 고객분은 교재금액 및 종료된 수업횟수의 수강료를 당근영어 통장계좌(우리: 1005-404-222447, ㈜캐럿솔루션즈)로 입금 또는 카드 재결제 완료 후 승인취소가 가능합니다.

            제 14 조(개인정보보호)
            ① 당근영어는 이용자의 정보수집 시 구매계약 이행에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.
            1. 성명
            2. 주소(국내 거주자에 한함)
            3. 연락처
            4. 희망 ID
            5. 비밀번호
            6. e-mail 주소
            ② 당근영어는 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
            ③ 제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 당근영어가 집니다. 다만, 다음의 경우에는 예외로 합니다.
            1. 서비스 협력 업체와 학사관리에 필요한 최소한의 이용자 정보(성명, e-mail 주소)를 알려주는 경우
            2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
            ④ 당근영어는 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받는 자, 제공 목적 및 제공할 정보의 내용) 등 정보통신망이용촉진법에 관한 법률 제16조 제3항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
            ⑤ 이용자는 언제든지 당근영어가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 당근영어는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 당근영어는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
            ⑥ 당근영어는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
            ⑦ 당근영어 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.

            제 15 조(당근영어의 의무)
            ① 당근영어는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화•용역을 제공하는데 최선을 다하여야 합니다.
            ② 당근영어는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
            ③ 당근영어는 상품이나 용역에 대하여 「표시•광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시•광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
            ④ 당근영어는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.

            제 16 조( 회원의 ID 및 비밀번호에 대한 의무)
            ① 제15조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
            ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
            ③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 당근영어에 통보하고 당근영어의 안내가 있는 경우에는 그에 따라야 합니다.

            제 17 조(이용자의 의무)
            이용자는 다음 행위를 하여서는 안됩니다.
            1. 신청 또는 변경 시 허위내용의 등록
            2. 당근영어에 게시된 정보의 변경
            3. 당근영어가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
            4. 당근영어 기타 제3자의 저작권 등 지적재산권에 대한 침해
            5. 당근영어 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            6. 외설 또는 폭력적인 메시지•화상•음성 기타 공서양속에 반하는 정보를 당근영어에 공개 또는 게시하는 행위

            제 18 조(당근영어와 연결사이트 간의 관계)
            ① 당근영어와 연결 사이트란 하이퍼 링크(예 : 하이퍼 링크의 대상에는 문자, 그림 및 동화상등이 포함됨)방식 등으로 연결된 경우를 말합니다.
            ② 당근영어는 연결 사이트가 독자적으로 제공하는 서비스에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않습니다.

            제 19 조(저작권의 귀속 및 이용제한)
            ① 당근영어가 작성한 저작물에 대한 저작권 기타 지적재산권은 당근영어에 귀속됩니다.
            ② 이용자는 당근영어를 이용함으로써 얻은 정보를 당근영어의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.

            제 20 조(분쟁해결)
            ① 당근영어는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치•운영합니다.
            ② 당근영어는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 줍니다.
            ③ 당근영어와 이용자간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.

            제 21 조(재판권 및 준거 법)
            ① 당근영어와 이용자간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.
            ② 당근영어와 이용자간에 제기된 전자거래 소송에는 한국 법을 적용합니다.

            부 칙(시행일) 본 약관은 2001년 2월 13일부터 적용합니다.
            `,

      Privacy: `
            ㈜캐럿솔루션즈(이하 “당근영어” 또는 “회사”라고 함)은 개인정보보호법, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 지켜야할 관련 법령상의 개인정보보호 규정을 준수하고 있습니다.
            당사의 개인정보처리방침은 정부의 법률/지침 변경 혹은 당근영어의 약관 및 내부 정책에 따라 변경될 수 있으며, 이를 개정/변경하는 경우 당사의 홈페이지에 변경사항을 즉시 게시하고 있습니다.
            
            본 개인정보처리방침의 목차는 아래와 같습니다.
            1.	수집하는 개인정보의 항목 및 수집방법
            2.	개인정보의 수집 및 이용목적
            3.	개인정보의 보유 및 이용기간
            4.	개인정보의 파기절차 및 방법
            5.	개인정보
            6.	수집한 개인정보의 위탁
            7.	이용자 및 법정대리인의 권리와 그 행사방법
            8.	개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
            9.	개인정보 위탁 현황
            10.	개인정보 관리 책임자
            11.	고지의 의무
            
            1. 수집하는 개인정보의 항목 및 수집방법
            당근영어는 온라인 및 모바일 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
            
            가. 수집하는 개인정보 항목
            1) 회원
            최초 회원가입 시 회원식별 및 최적화된 서비스 제공을 위해 아래와 같은 정보를 수집합니다.
            - 필수항목: 이름, 아이디, 비밀번호, 이메일, 휴대전화
            - 선택항목: 영문이름, 주소, 일반전화
            2) 비회원
            무료수업 신청, 비회원 구매, 학습문의 시 아래와 같은 정보를 수집합니다.
            - 필수항목 : 이름, 이메일, 휴대전화
            - 선택항목 : 추천인 명
            3) 서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
            - 웹사이트 이용 시: 서비스 이용기록, 접속로그, 쿠키, 접속IP정보, 결제기록
            - 모바일 이용 시: 서비스 이용 기록, 접속 로그, 기기고유번호(디바이스 아이디 또는 IMEI)
            4) 고객 상담 시 서비스 품질 향상을 위해 담당자 연결 후 통화내용이 녹음되어 아래와 같은 정보들을 수집합니다.
            - 통화이력 및 녹음 파일
            5) 수업 복습 컨텐츠를 위해 수업 내용이 녹음/녹화되어 정보를 수집합니다.
            
            
            나. 수집방법
            1) 홈페이지를 통한 회원가입, 서비스 이용 결제, 이벤트 응모, 전화, 팩스, 고객센터 문의 및 상담
            2) 생성정보 수집 툴을 통한 수집
            
            2. 개인정보의 수집 및 이용목적
            당근영어는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
            
            ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공, 구매 및 요금 결제, 본인인증, 물품배송 또는 청구지 등 발송
            
            ο 회원 관리
            회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지,
            가입 의사 확인, 연령확인, 불만처리 등 민원처리, 고지사항 전달
            
            ο 마케팅 및 광고에 활용
            신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재,
            접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
            
            ο 기타
            가입횟수 제한, 분쟁조정을 위한 기록보존 및 기타 통계자료 산출
            
            3. 개인정보의 보유 및 이용기간
            회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.
            파기절차 및 방법은 다음과 같습니다.
            
            ο 파기절차:회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후
            별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
            정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다.
            별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
            ο 파기방법:전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
            ο 보유기간:이용계약에 따른 회원자격이 유지되는 기간동안
            그러나 회원탈퇴 후에도 관계법령의 규정에 의하여 보존할 필요가 있는 경우 당근영어는 아래와 같이 일정 기간동안 회원정보를 보관합니다.
            회원 및 비회원 통화 이력은 상담 종료 후 30일동안 보관된 후 파기됩니다.
            
            4. 개인정보의 파기절차 및 방법
            개인정보의 보유 및 이용기간은 다음과 같습니다.
            
            ο 보유항목:수집항목과 같습니다.
            ο 보유근거:이용약관 및 개인정보취급방침
            ο 보유기간:이용계약에 따른 회원자격이 유지되는 기간동안 그러나 회원탈퇴 후에도 관계법령의 규정에 의하여
            보존할 필요가 있는 경우 당근영어는 아래와 같이 일정 기간동안 회원정보를 보관합니다.
            표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한 법률)
            계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
            대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
            소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
            신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
            통신비밀보호법에 따른 통신사실확인자료 3개월
            
            5. 개인정보
            당근영어는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
            
            이용자들이 사전에 동의한 경우
            - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
            
            6. 이용자 및 법정대리인의 권리와 그 행사방법
            이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를
            조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
            이용자 혹은 만 14세 미만 아동의 개인정보 조회를 위해서는 ‘정보수정’ 을,
            가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭 하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
            혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.
            귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전 까지 개인정보를 이용 또는 제공하지 않습니다.
            또한 잘못된 개인정보를 제3자 에게 이미 제공한 경우에는
            정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
            당근영어는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는
            당근영어가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고
            그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
            
            7. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
            당근영어는 귀하의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다.
            쿠키란 당근영어의 웹사이트를 운영하는데 이용되는 서버가
            귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
            당근영어는 다음과 같은 목적을 위해 쿠키를 사용합니다.
            
            쿠키 등 사용 목적
            - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적,
            각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
            
            쿠키 설정 거부 방법
            - 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써
            모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
            - 설정방법 예(인터넷 익스플로러의 경우): 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
            (단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.)
            
            8. 개인정보 위탁 현황
            고객지원 및 서비스 제공을 위해 아래와 같이 개인정보 취급 업무를 외부 업체에 위탁하여 운영하고 있습니다.
            위탁계약시 개인정보보호의 안전을 기하기 위하여 개인정보보호 관련 지시 엄수,
            개인정보에 관한 유출금지 및 사고시의 책임부담 등을 명확히 규정하고 위탁계약 내용에 포함하고 있습니다.
            수탁업체
            위탁목적
            개인정보 보유 및 이용기간
            ㈜다우기술	SMS 발송대행 계약종료 시까지
            
            9.개인정보 국외제공 현황
            제공받는 자 : CARROT EPOST EDUCENTER inc.
            아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
            
            정보관리책임자 및 연락처 : 20th Floor, Stata Bldg 2000, Emerald Avenue, Ortigas Center, Pasig City. zimmymin@carrotglobal.com
            제공목적 : 전화/화상 외국어 서비스의 제공
            제공일시 : 필리핀 전화/화상 외국어 서비스 이용시
            
            
            제공되는 개인정보 : 아이디, 전화번호
            제공되는 국가 : 필리핀
            개인정보 이용기간 : 계약종료 후 고객지원업무를 위해 1년간 보존
            
            
            10. 개인정보 관리 책임자
            당근영어는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여
            아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
            
            개인정보관리책임자 성명 : 김범진 소장 
            전화번호 : 02-518-2603 (평일 : 9시 ~ 6시까지) 
            이메일 : ciankim@carrotglobal.com
            
            기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.
            •	개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
            •	대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
            •	경찰청 사이버수사국 (police.go.kr / 국번없이 182)
            
            11. 고지의 의무
            현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는
            변경사항 시행일의 7일전부터 고지할 것입니다.
            
            - 공고일자: 2021년 5월 26일
            - 시행일자: 2021년 6월 2일
            `,

      backHome() {
        router.push({ name: "home", query: { idxLeSiteMain } });
      },

      allCheckBtn() {
        if (signUser.allChecked) {
          signUser.termsOfUseCheckbox = true;
          signUser.privacyCheckbox = true;
        } else {
          signUser.termsOfUseCheckbox = false;
          signUser.privacyCheckbox = false;
        }
      },

      checkAllAgreements() {
        if (signUser.termsOfUseCheckbox && signUser.privacyCheckbox) {
          signUser.allChecked = true;
        } else {
          signUser.allChecked = false;
        }
      },

      nextSignPage() {
        if (signUser.termsOfUseCheckbox && signUser.privacyCheckbox) {
          router.push({ name: "signUp2" });
        } else {
          alert("필수 약관을 동의 해주셔야 합니다.");
        }
      },
    });

    return {
      signUser,
    };
  },
};
</script>
<style scoped></style>
