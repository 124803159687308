import { createRouter, createWebHistory } from "vue-router";
import routes from "vue-auto-routing";
import { createRouterLayout } from "vue-router-layout";

import index from "@lifeeduWeb/pages/index.vue";
import home from "@lifeeduWeb/pages/home.vue";
import login from "@lifeeduWeb/pages/login/login.vue";
import findId from "@lifeeduWeb/pages/login/findId.vue";
import findPw from "@lifeeduWeb/pages/login/findPw.vue";
import signUp1 from "@lifeeduWeb/pages/signUp/signUp1.vue";
import signUp2 from "@lifeeduWeb/pages/signUp/signUp2.vue";
import signUpOk from "@lifeeduWeb/pages/signUp/signUpOk.vue";
import myClass from "@lifeeduWeb/pages/myClass.vue";
import myPage from "@lifeeduWeb/pages/myPage.vue";
import noticeList from "@lifeeduWeb/pages/learningSupport/noticeList.vue";
import noticeDetail from "@lifeeduWeb/pages/learningSupport/noticeDetail.vue";
import faq from "@lifeeduWeb/pages/learningSupport/faq.vue";
import step from "@lifeeduWeb/pages/enrolment/step.vue";
// import goEnrolment from "@lifeeduWeb/pages/enrolment/goEnrolment.vue";
import newEnrolment from "@lifeeduWeb/pages/enrolment/newEnrolment.vue";
import newEnrolmentClass from "@lifeeduWeb/pages/enrolment/newEnrolmentClass.vue";
import check from "@lifeeduWeb/pages/enrolment/check.vue";
import classDetail from "@lifeeduWeb/pages/enrolment/classDetail.vue";
import checkDetail from "@lifeeduWeb/pages/enrolment/checkDetail.vue";
import classOk from "@lifeeduWeb/pages/enrolment/classOk.vue";
import classList from "@lifeeduWeb/pages/enrolment/classList.vue";
import classOkMobile from "@lifeeduWeb/pages/enrolment/classOkMobile.vue";
// import yearly from "@lifeeduWeb/pages/courseIntroduce/yearly.vue";
import adultEngPageAll from "@lifeeduWeb/pages/courseIntroduce/adultEng/pageAll.vue";
import adultEngPage1 from "@lifeeduWeb/pages/courseIntroduce/adultEng/page1.vue";
import adultEngPage2 from "@lifeeduWeb/pages/courseIntroduce/adultEng/page2.vue";
import adultEngPage3 from "@lifeeduWeb/pages/courseIntroduce/adultEng/page3.vue";
import adultEngPage4 from "@lifeeduWeb/pages/courseIntroduce/adultEng/page4.vue";
import adultEngPage5 from "@lifeeduWeb/pages/courseIntroduce/adultEng/page5.vue";
import adultEngPage6 from "@lifeeduWeb/pages/courseIntroduce/adultEng/page6.vue";
import adultChiPageAll from "@lifeeduWeb/pages/courseIntroduce/adultChi/pageAll.vue";
import adultChiPage1 from "@lifeeduWeb/pages/courseIntroduce/adultChi/page1.vue";
import adultChiPage2 from "@lifeeduWeb/pages/courseIntroduce/adultChi/page2.vue";
import adultChiPage3 from "@lifeeduWeb/pages/courseIntroduce/adultChi/page3.vue";
import adultChiPage4 from "@lifeeduWeb/pages/courseIntroduce/adultChi/page4.vue";
import adultChiPage5 from "@lifeeduWeb/pages/courseIntroduce/adultChi/page5.vue";
import adultJapPageAll from "@lifeeduWeb/pages/courseIntroduce/adultJap/pageAll.vue";
import adultJapPage1 from "@lifeeduWeb/pages/courseIntroduce/adultJap/page1.vue";
import adultJapPage2 from "@lifeeduWeb/pages/courseIntroduce/adultJap/page2.vue";
import adultJapPage3 from "@lifeeduWeb/pages/courseIntroduce/adultJap/page3.vue";
import adultJapPage4 from "@lifeeduWeb/pages/courseIntroduce/adultJap/page4.vue";
import adultEtc from "@lifeeduWeb/pages/courseIntroduce/adultEtc.vue";
import junior from "@lifeeduWeb/pages/courseIntroduce/junior.vue";
import selfLearning from "@/lifeeduWeb/pages/selfLearning.vue";
import questionPage from "@/lifeeduWeb/pages/questionPage.vue";

const RouterLayout = createRouterLayout((layout) => {
  return import("@lifeeduWeb/pages/" + layout + ".vue");
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: home,
      children: routes,
    },
    {
      // 메인
      path: "/home",
      name: "home",
      component: home,
      children: routes,
    },
    {
      // 로그인
      path: "/login/login",
      name: "login",
      component: login,
      children: routes,
      meta: {
        title: "로그인",
      },
    },
    {
      // 아이디 찾기
      path: "/login/findId",
      name: "findId",
      component: findId,
      children: routes,
      meta: {
        title: "아이디 찾기",
      },
    },
    {
      // 비밀번호 찾기
      path: "/login/findPw",
      name: "findPw",
      component: findPw,
      children: routes,
      meta: {
        title: "비밀번호 찾기",
      },
    },
    {
      // 회원가입 - 약관동의
      path: "/signUp/signUp1",
      name: "signUp1",
      component: signUp1,
      children: routes,
      meta: {
        title: "회원가입",
      },
    },
    {
      // 회원가입 - 폼 입력
      path: "/signUp/signUp2",
      name: "signUp2",
      component: signUp2,
      children: routes,
      meta: {
        title: "회원가입",
      },
    },
    {
      // 회원가입 - 완료
      path: "/signUp/signUpOk",
      name: "signUpOk",
      component: signUpOk,
      children: routes,
      meta: {
        title: "회원가입",
      },
    },
    {
      // 나의 학습방
      path: "/myClass",
      name: "myClass",
      component: myClass,
      children: routes,
      meta: {
        title: "나의 학습방",
      },
    },
    {
      // 회원정보수정
      path: "/myPage",
      name: "myPage",
      component: myPage,
      children: routes,
      meta: {
        title: "회원정보수정",
      },
    },
    {
      // 공지사항 - 리스트
      path: "/learningSupport/noticeList",
      name: "noticeList",
      component: noticeList,
      children: routes,
      meta: {
        title: "공지사항",
      },
    },
    {
      // 공지사항 - 상세
      path: "/learningSupport/noticeDetail",
      name: "noticeDetail",
      component: noticeDetail,
      children: routes,
      meta: {
        title: "공지사항",
      },
    },
    {
      // faq
      path: "/learningSupport/faq",
      name: "faq",
      component: faq,
      children: routes,
      meta: {
        title: "FAQ",
      },
    },
    {
      // 수강절차 안내
      path: "/enrolment/step",
      name: "step",
      component: step,
      children: routes,
      meta: {
        title: "수강절차 안내",
      },
    },
    // {
    //   // 수강신청 바로가기
    //   path: "/enrolment/goEnrolment",
    //   name: "goEnrolment",
    //   component: goEnrolment,
    //   children: routes,
    //   meta: {
    //     title: "수강신청 바로가기",
    //   },
    // },
    {
      // 신규 신청
      path: "/enrolment/newEnrolment",
      name: "newEnrolment",
      component: newEnrolment,
      children: routes,
      meta: {
        title: "수강신청 바로가기",
      },
    },
    {
      // 신규 신청 (처음부터 신청하기 페이지)
      path: "/enrolment/newEnrolmentClass",
      name: "newEnrolmentClass",
      component: newEnrolmentClass,
      children: routes,
      meta: {
        title: "수강신청 바로가기",
      },
    },
    {
      // 신규 신청 이후 결제 안하고 이탈
      path: "/enrolment/check",
      name: "check",
      component: check,
      children: routes,
      meta: {
        title: "수강신청 바로가기",
      },
    },
    {
      // 기존 수강 내역
      path: "/enrolment/classDetail",
      name: "classDetail",
      component: classDetail,
      children: routes,
      meta: {
        title: "수강신청 바로가기",
      },
    },
    {
      // 신규 신청 이후 결제
      path: "/enrolment/checkDetail",
      name: "checkDetail",
      component: checkDetail,
      children: routes,
      meta: {
        title: "수강신청 바로가기",
      },
    },
    {
      // 수강신청 완료 이후 (신청 내역, 결제 까지)
      path: "/enrolment/classOk",
      name: "classOk",
      component: classOk,
      children: routes,
      meta: {
        title: "수강신청 바로가기",
      },
    },
    {
      // 수강신청 내역확인
      path: "/enrolment/classList",
      name: "classList",
      component: classList,
      children: routes,
      meta: {
        title: "수강신청 내역확인",
      },
    },
    {
      // 수강신청 내역확인
      path: "/enrolment/classOkMobile",
      name: "classOkMobile",
      component: classOkMobile,
      children: routes,
      meta: {
        title: "수강신청 결제확인",
      },
    },
    // {
    //   // 연간 과정일정
    //   path: "/courseIntroduce/yearly",
    //   name: "yearly",
    //   component: yearly,
    //   children: routes,
    //   meta: {
    //     title: "연간 과정일정",
    //   },
    // },
    {
      // 성인 과정 (영어, 전체 커리큘럼 맵)
      path: "/courseIntroduce/adultEng/pageAll",
      name: "adultEngPageAll",
      component: adultEngPageAll,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 영어 - 일반회화
      path: "/courseIntroduce/adultEng/page1",
      name: "adultEngPage1",
      component: adultEngPage1,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 영어 - 비즈니스 회화
      path: "/courseIntroduce/adultEng/page2",
      name: "adultEngPage2",
      component: adultEngPage2,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 영어 - 토론회화
      path: "/courseIntroduce/adultEng/page3",
      name: "adultEngPage3",
      component: adultEngPage3,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 영어 - 시험,인터뷰대비
      path: "/courseIntroduce/adultEng/page4",
      name: "adultEngPage4",
      component: adultEngPage4,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 영어 - 로이터뉴스
      path: "/courseIntroduce/adultEng/page5",
      name: "adultEngPage5",
      component: adultEngPage5,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 영어 - globap topic
      path: "/courseIntroduce/adultEng/page6",
      name: "adultEngPage6",
      component: adultEngPage6,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 (중국어, 전체 커리큘럼 맵)
      path: "/courseIntroduce/adultChi/pageAll",
      name: "adultChiPageAll",
      component: adultChiPageAll,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 중국어 - 일반회화
      path: "/courseIntroduce/adultChi/page1",
      name: "adultChiPage1",
      component: adultChiPage1,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 중국어 - 비즈니스 회화
      path: "/courseIntroduce/adultChi/page2",
      name: "adultChiPage2",
      component: adultChiPage2,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 중국어 - 토론회화
      path: "/courseIntroduce/adultChi/page3",
      name: "adultChiPage3",
      component: adultChiPage3,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 중국어 - 시험대비과정
      path: "/courseIntroduce/adultChi/page4",
      name: "adultChiPage4",
      component: adultChiPage4,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 중국어 - 테마과정
      path: "/courseIntroduce/adultChi/page5",
      name: "adultChiPage5",
      component: adultChiPage5,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 (중국어, 전체 커리큘럼 맵)
      path: "/courseIntroduce/adultJap/pageAll",
      name: "adultJapPageAll",
      component: adultJapPageAll,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 일본어 - 일반회화
      path: "/courseIntroduce/adultJap/page1",
      name: "adultJapPage1",
      component: adultJapPage1,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 일본어 - 비즈니스 회화
      path: "/courseIntroduce/adultJap/page2",
      name: "adultJapPage2",
      component: adultJapPage2,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 일본어 - 토론회화
      path: "/courseIntroduce/adultJap/page3",
      name: "adultJapPage3",
      component: adultJapPage3,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 일본어 - 테마과정
      path: "/courseIntroduce/adultJap/page4",
      name: "adultJapPage4",
      component: adultJapPage4,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 성인 과정 - 제2 외국어
      path: "/courseIntroduce/adultEtc",
      name: "adultEtc",
      component: adultEtc,
      children: routes,
      meta: {
        title: "성인 과정",
      },
    },
    {
      // 주니어 과정
      path: "/courseIntroduce/junior",
      name: "junior",
      component: junior,
      children: routes,
      meta: {
        title: "주니어 과정",
      },
    },
    {
      // 자기주도학습
      path: "/selfLearning",
      name: "selfLearning",
      component: selfLearning,
      children: routes,
      meta: {
        title: "주니어 과정",
      },
    },
    {
      // 자기주도학습
      path: "/questionPage",
      name: "questionPage",
      component: questionPage,
      children: routes,
      meta: {
        title: "주니어 과정",
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, behavior: "smooth" };
  },
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   } else {
//     document.title = "평생교육";
//   }
//   next();
// });

export default router;
