<template>
  <div>
    <!-- pc -->
    <div class="invisible opacity-0 pc pc:opacity-100 pc:visible">
      <header id="header" class="fixed top-0 left-0 w-full bg-white border-b border-solid border-neutral-200 z-[2]">
        <div class="flex items-center justify-between pc-con-wrapper2">
          <div class="flex-none cursor-pointer logo max-w-[190px] h-12" @click="registUserInfo.goHome()">
            <img class="h-full" :src="companyLogo" alt="logo" />
          </div>

          <nav class="pc-nav">
            <ul class="flex gnb group">
              <li class="relative text-center">
                <p v-if="idxLeSiteMain != '52'" class="py-8 text-xl font-medium cursor-pointer px-11 text-neutral-950">
                  과정소개
                </p>
                <ul
                  v-if="idxLeSiteMain != '52'"
                  class="sub absolute opacity-0 invisible py-8 z-[3] w-full group-hover:opacity-100 group-hover:visible"
                >
                  <!-- <li @click="goPage('/courseIntroduce/yearly')">
                    <p class="py-3 text-lg font-medium cursor-pointer">연간 과정일정</p>
                  </li> -->
                  <li @click="goPage('/courseIntroduce/junior')">
                    <p class="py-3 text-lg font-medium cursor-pointer">주니어 과정</p>
                  </li>
                  <li @click="goPage('/courseIntroduce/adultEng/PageAll')">
                    <p class="py-3 text-lg font-medium cursor-pointer">성인 과정</p>
                  </li>
                </ul>
              </li>
              <li class="relative text-center">
                <p class="py-8 text-xl font-medium cursor-pointer px-11 text-neutral-950">수강신청</p>
                <ul class="sub absolute opacity-0 invisible py-8 z-[3] w-full group-hover:opacity-100 group-hover:visible">
                  <li v-if="idxLeSiteMain == '52'" @click="openGlobalsugang()">
                    <p class="py-3 text-lg font-medium cursor-pointer">수강신청 하기</p>
                  </li>
                  <li v-else @click="goPage('/enrolment/step')">
                    <p class="py-3 text-lg font-medium cursor-pointer">수강절차 안내</p>
                  </li>
                  <!-- <li @click="registUserInfo.goEnrolment()">
                    <p class="py-3 text-lg font-medium cursor-pointer">수강신청 바로가기</p>
                  </li> -->
                  <li @click="idxLeSiteMain == '52' ? opencheckGlobalsugang() : registUserInfo.goClassList()">
                    <p class="py-3 text-lg font-medium cursor-pointer">수강신청 내역확인</p>
                  </li>
                </ul>
              </li>
              <li class="relative text-center">
                <p class="py-8 text-xl font-medium cursor-pointer px-11 text-neutral-950">학습지원</p>
                <ul class="sub absolute opacity-0 invisible py-8 z-[3] w-full group-hover:opacity-100 group-hover:visible">
                  <li @click="goPage('/learningSupport/faq')">
                    <p class="py-3 text-lg font-medium cursor-pointer">FAQ</p>
                  </li>
                  <li @click="goPage('/learningSupport/noticeList')">
                    <p class="py-3 text-lg font-medium cursor-pointer">공지사항</p>
                  </li>
                </ul>
              </li>
              <li class="relative text-center" @click="registUserInfo.goMyClass('/myClass')">
                <p class="py-8 text-xl font-medium cursor-pointer px-11 text-neutral-950">나의 학습방</p>
              </li>
              <li v-if="idxLeSiteMain == '52'" class="relative text-center" @click="registUserInfo.goSelfLearning()">
                <p class="py-8 text-xl font-medium cursor-pointer px-11 text-neutral-950">자기주도학습</p>
              </li>
            </ul>
          </nav>

          <ul v-if="!accessToken" class="flex gap-6">
            <li class="text-lg font-medium cursor-pointer text-neutral-500" @click="goPage('/login/login')">로그인</li>
            <li class="text-lg font-medium cursor-pointer text-neutral-500" @click="goPage('/signup/signUp1')">회원가입</li>
          </ul>
          <ul v-if="accessToken" class="flex gap-6">
            <li class="text-lg font-medium cursor-pointer text-neutral-500" @click="registUserInfo.goToMyPage()">마이페이지</li>
            <li class="text-lg font-medium cursor-pointer text-neutral-500" @click="registUserInfo.getLogout()">로그아웃</li>
          </ul>
        </div>
        <div class="header_bg"></div>
      </header>
    </div>

    <!-- mobile -->
    <div class="mobile pc:opacity-0 pc:invisible">
      <header id="header-m" class="fixed top-0 left-0 w-full bg-white border-b border-solid border-neutral-200 z-[2]">
        <div class="flex items-center justify-between py-6 mobile-con-wrapper1">
          <!-- 메인페이지만 -->
          <div class="cursor-pointer w-[150px] h-[50px] overflow-hidden" @click="registUserInfo.goHomeMobile()">
            <img :src="companyLogo" alt="logo" class="object-contain h-full" />
          </div>

          <!-- 메인 외 서브페이지에만 -->
          <!-- <div class="w-full">
            <div class="flex justify-center grow">
              <p class="w-5 text-lg font-bold">&nbsp;</p>
              <p class="text-lg font-bold text-neutral-950">
                {{ pageTitle }}
              </p>
            </div>
          </div> -->

          <div class="flex-none cursor-pointer" @click="toggleMobileNav">
            <img src="@/lifeeduWeb/assets/images/header/menu-on.png" alt="icon" class="w-5" />
          </div>
        </div>

        <!-- 서브 페이지 들어갈 시 하단 메뉴 노출 -->
        <div v-show="isNavList" class="pb-4 pc:hidden">
          <ul class="flex items-center justify-center gap-5">
            <li class="invisible w-0">&nbsp;</li>
            <li
              v-for="(menu, index) in menus"
              :key="index"
              @click="goPage(menu.path)"
              class="flex-none text-sm font-medium cursor-pointer text-neutral-950 active:text-orange-500"
              :class="{
                'text-orange-500': isMenuActive(menu.path),
              }"
            >
              {{ menu.label }}
            </li>
            <li class="invisible w-0">&nbsp;</li>
          </ul>
        </div>

        <nav class="fixed top-0 left-0 w-full h-full bg-white mobile-nav" :class="{ hidden: !isMobileNavOpen }">
          <div class="flex items-center justify-between py-6 mobile-con-wrapper1">
            <div class="cursor-pointer w-[150px] h-[50px]" @click="registUserInfo.goHomeMobile()">
              <img :src="companyLogo" alt="logo" class="object-contain h-full" />
            </div>
            <div class="flex-none cursor-pointer" @click="toggleMobileNav">
              <img src="@/lifeeduWeb/assets/images/header/menu-off.png" alt="icon" class="w-5" />
            </div>
          </div>
          <div class="py-8 border-t border-b-8 border-solid border-neutral-100">
            <ul v-if="!accessToken" class="flex gap-6 mobile-con-wrapper1">
              <li class="font-medium cursor-pointer text-neutral-500" @click="registUserInfo.goLoginMobile()">로그인</li>
              <li class="font-medium cursor-pointer text-neutral-500" @click="registUserInfo.goSignupMobile()">회원가입</li>
            </ul>

            <ul v-if="accessToken" class="flex gap-6 mobile-con-wrapper1">
              <li class="font-medium cursor-pointer text-neutral-500" @click="registUserInfo.goToMyPage()">마이페이지</li>
              <li class="font-medium cursor-pointer text-neutral-500" @click="registUserInfo.getLogout()">로그아웃</li>
            </ul>
          </div>
          <ul>
            <li v-if="idxLeSiteMain != '52'">
              <div
                class="flex items-center justify-between py-6 border-b border-solid cursor-pointer mobile-con-wrapper1 border-neutral-200"
                @click="toggleSubMenu(0)"
                :class="[{ open: subMenuOpen[0] }]"
              >
                <p class="text-lg font-bold text-neutral-950">과정소개</p>
                <div class="flex-none">
                  <img
                    :src="
                      subMenuOpen[0]
                        ? require('@/lifeeduWeb/assets/images/header/arrow-up.png')
                        : require('@/lifeeduWeb/assets/images/header/arrow-down.png')
                    "
                    alt=""
                    class="w-4"
                  />
                </div>
              </div>
              <div class="bg-neutral-100 mobile-sub" v-if="subMenuOpen[0]">
                <ul class="flex flex-col gap-5 mobile-con-wrapper1 py-7">
                  <!-- <li
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    @click="registUserInfo.goYearlyMobile()"
                  >
                    연간 과정일정
                  </li> -->
                  <li
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    @click="registUserInfo.goJuniorMobile()"
                  >
                    주니어 과정
                  </li>
                  <li
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    @click="registUserInfo.goAdultEngMobile()"
                  >
                    성인 과정
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div
                class="flex items-center justify-between py-6 border-b border-solid cursor-pointer mobile-con-wrapper1 border-neutral-200"
                @click="toggleSubMenu(1)"
                :class="[{ open: subMenuOpen[1] }]"
              >
                <p class="text-lg font-bold text-neutral-950">수강신청</p>
                <div class="flex-none">
                  <img
                    :src="
                      subMenuOpen[1]
                        ? require('@/lifeeduWeb/assets/images/header/arrow-up.png')
                        : require('@/lifeeduWeb/assets/images/header/arrow-down.png')
                    "
                    alt=""
                    class="w-4"
                  />
                </div>
              </div>
              <div class="bg-neutral-100 mobile-sub" v-if="subMenuOpen[1]">
                <ul class="flex flex-col gap-5 mobile-con-wrapper1 py-7">
                  <li
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    v-if="idxLeSiteMain == '52'"
                    @click="openGlobalsugang()"
                  >
                    수강신청 하기
                  </li>
                  <li
                    v-else
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    @click="registUserInfo.goStepMobile()"
                  >
                    수강절차 안내
                  </li>
                  <!-- <li
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    @click="registUserInfo.goEnrolmentMobile()"
                  >
                    수강신청 바로가기
                  </li> -->
                  <li
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    v-if="idxLeSiteMain == '52'"
                    @click="opencheckGlobalsugang()"
                  >
                    수강신청 내역확인
                  </li>
                  <li
                    v-else
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    @click="registUserInfo.goClassListMobile()"
                  >
                    수강신청 내역확인
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div
                class="flex items-center justify-between py-6 border-b border-solid cursor-pointer mobile-con-wrapper1 border-neutral-200"
                @click="toggleSubMenu(2)"
                :class="[{ open: subMenuOpen[2] }]"
              >
                <p class="text-lg font-bold text-neutral-950">학습지원</p>
                <div class="flex-none">
                  <img
                    :src="
                      subMenuOpen[2]
                        ? require('@/lifeeduWeb/assets/images/header/arrow-up.png')
                        : require('@/lifeeduWeb/assets/images/header/arrow-down.png')
                    "
                    alt=""
                    class="w-4"
                  />
                </div>
              </div>
              <div class="bg-neutral-100 mobile-sub" v-if="subMenuOpen[2]">
                <ul class="flex flex-col gap-5 mobile-con-wrapper1 py-7">
                  <li
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    @click="registUserInfo.goFaqMobile()"
                  >
                    FAQ
                  </li>
                  <li
                    class="pc:text-sm font-medium cursor-pointer text-neutral-950 text-[16px]"
                    @click="registUserInfo.goNoticeListMobile()"
                  >
                    공지사항
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div
                class="flex items-center justify-between py-6 cursor-pointer mobile-con-wrapper1"
                @click="registUserInfo.goMyClassMobile('/myClass')"
              >
                <p class="text-lg font-bold text-neutral-950">나의 학습방</p>
              </div>
            </li>
            <li>
              <div
                v-if="idxLeSiteMain == '52'"
                class="flex items-center justify-between py-6 border-t border-solid cursor-pointer mobile-con-wrapper1 border-neutral-200"
                @click="registUserInfo.goSelfLearning()"
              >
                <p class="text-lg font-bold text-neutral-950">자기주도학습</p>
              </div>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, watch, onBeforeMount, computed, reactive } from "vue";
import { useStore } from "vuex";
import axios from "@lifeeduWeb/plugins/axios.js";

export default {
  components: {},
  props: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const idxLeSiteMain = store.state.idxLeSiteMain;
    const registUserInfo = reactive({
      userLoginInfo: {},

      goToMyPage() {
        router
          .push({
            path: "/mypage",
            query: {
              idxLeSiteMain: idxLeSiteMain,
            },
          })
          .then(() => {
            isMobileNavOpen.value = false;
          });
      },

      getLogout() {
        axios.get(`/user/auth/logout`).then(function (res) {
          if (res.data.success === true) {
            localStorage.removeItem("LifeEduUserVuex");
            store.commit("setAccessToken", null);
            router.push({ path: "/home", query: { idxLeSiteMain } });
            alert("로그아웃 되었습니다.");
          } else {
            alert(res.data.message);
          }
        });
      },

      goHome() {
        router
          .push({
            path: "/home",
            query: {
              idxLeSiteMain: idxLeSiteMain,
            },
          })
          .then(() => {});
      },

      goHomeMobile() {
        router
          .push({
            path: "/home",
            query: {
              idxLeSiteMain: idxLeSiteMain,
            },
          })
          .then(() => {
            isMobileNavOpen.value = false;
          });
      },

      goLoginMobile() {
        router
          .push({
            path: "/login/login",
            query: {
              idxLeSiteMain: idxLeSiteMain,
            },
          })
          .then(() => {
            isMobileNavOpen.value = false;
          });
      },

      goSignupMobile() {
        router
          .push({
            path: "/signup/signUp1",
          })
          .then(() => {
            isMobileNavOpen.value = false;
          });
      },

      // goYearlyMobile() {
      //   router
      //     .push({
      //       path: "/courseIntroduce/yearly",
      //     })
      //     .then(() => {
      //       isMobileNavOpen.value = false;
      //       isNavList.value = true;
      //     });
      // },

      goAdultEngMobile() {
        router
          .push({
            path: "/courseIntroduce/adultEng/PageAll",
          })
          .then(() => {
            isMobileNavOpen.value = false;
            isNavList.value = true;
          });
      },

      goJuniorMobile() {
        router
          .push({
            path: "/courseIntroduce/junior",
          })
          .then(() => {
            isMobileNavOpen.value = false;
            isNavList.value = true;
          });
      },

      goStepMobile() {
        router
          .push({
            path: "/enrolment/step",
          })
          .then(() => {
            isMobileNavOpen.value = false;
            isNavList.value = true;
          });
      },

      goEnrolmentMobile() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            router
              .push({
                path: "/enrolment/newEnrolment",
                query: { idxLeSiteMain },
              })
              .then(() => {
                isMobileNavOpen.value = false;
              });
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router
              .push({
                path: "/login/login",
              })
              .then(() => {
                isMobileNavOpen.value = false;
              });
          }
        });
      },

      goEnrolment() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            router.push({
              path: "/enrolment/newEnrolment",
              query: { idxLeSiteMain },
            });
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
          }
        });
      },

      goClassList() {
        const token = store.getters.getAccessToken;
        const isCancleFunction = store.state.isCancleFunction;
        const params = {
          token: token,
          isCancleFunction: isCancleFunction,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            router.push({
              path: "/enrolment/classList",
              query: { idxLeSiteMain },
            });
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
          }
        });
      },

      goClassListMobile() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            router
              .push({
                path: "/enrolment/classList",
                query: { idxLeSiteMain },
              })
              .then(() => {
                isMobileNavOpen.value = false;
              });
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router
              .push({
                path: "/login/login",
              })
              .then(() => {
                isMobileNavOpen.value = false;
              });
          }
        });
      },

      goMyClass() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            router.push({
              path: "/myClass",
              query: { idxLeSiteMain },
            });
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
          }
        });
      },

      goMyClassMobile() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            router
              .push({
                path: "/myClass",
                query: { idxLeSiteMain },
              })
              .then(() => {
                isMobileNavOpen.value = false;
              });
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router
              .push({
                path: "/login/login",
              })
              .then(() => {
                isMobileNavOpen.value = false;
              });
          }
        });
      },

      goFaqMobile() {
        router
          .push({
            path: "/learningSupport/faq",
          })
          .then(() => {
            isMobileNavOpen.value = false;
            isNavList.value = true;
          });
      },

      goNoticeListMobile() {
        router
          .push({
            path: "/learningSupport/noticeList",
          })
          .then(() => {
            isMobileNavOpen.value = false;
            isNavList.value = true;
          });
      },

      getCheckToken() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
          } else {
            axios.get(`/user/auth/logout`).then(function (res) {
              if (res.data.success === true) {
                localStorage.removeItem("LifeEduUserVuex");
                store.commit("setAccessToken", null);
              }
            });
          }
        });
      },

      goSelfLearning() {
        const token = store.getters.getAccessToken;
        const params = {
          token: token,
        };
        axios.get(`/user/auth/token`, { params }).then(function (res) {
          if (res.data.success === true) {
            router.push({
              path: "/selfLearning",
            });
            isMobileNavOpen.value = false;
          } else {
            alert("로그인이 필요한 서비스 입니다.");
            router.push({
              path: "/login/login",
            });
            isMobileNavOpen.value = false;
          }
        });
      },
    });

    const goPage = (path) => {
      router.push(path);
    };

    const openGlobalsugang = () => {
      const token = store.getters.getAccessToken;
      const params = {
        token: token,
      };
      axios.get(`/user/auth/token`, { params }).then(function (res) {
        if (res.data.success === true) {
          window.location.href = `https://apply.carrotglobal.com/lnk/busanedu?id=${store.state.userId}&accesscode=2024`;
        } else {
          alert("로그인이 필요한 서비스 입니다.");
          router.push({
            path: "/login/login",
          });
        }
      });
    };
    const opencheckGlobalsugang = () => {
      const token = store.getters.getAccessToken;
      const params = {
        token: token,
      };
      axios.get(`/user/auth/token`, { params }).then(function (res) {
        if (res.data.success === true) {
          if (location.origin.includes("stage")) {
            window.location.href = "https://stage-itmsugang.carrotenglish.net/lnk/hunitest?loginPageMode=history";
          } else {
            window.location.href = "https://apply.carrotglobal.com/lnk/busanedu?loginPageMode=history";
          }
        } else {
          alert("로그인이 필요한 서비스 입니다.");
          router.push({
            path: "/login/login",
          });
        }
      });
    };

    // 각 페이지 타이틀
    const pageTitle = ref("");

    // 메인, 서브 헤더 구분
    const isMainPage = window.location.pathname === "/home";

    // 각 페이지 접속했을 때 하위 메뉴 있으면
    const isNavList = ref(true);
    const updateIsNavList = () => {
      const currentPath = window.location.pathname;

      // 아래 페이지는 하위 메뉴 안 보여주기
      isNavList.value =
        currentPath !== "/home" &&
        currentPath !== "/login/login" &&
        currentPath !== "/myClass" &&
        currentPath !== "/myPage" &&
        currentPath !== "/signup/signUp1" &&
        currentPath !== "/signup/signUp2" &&
        currentPath !== "/selfLearning";
    };
    onBeforeMount(() => {
      updateIsNavList();
    });
    const originalMenus = ref([
      // {
      //   label: "연간 과정일정",
      //   path: "/courseIntroduce/yearly",
      //   group: "menuGroup1",
      // },
      {
        label: "성인 과정",
        path: "/courseIntroduce/adultEng/PageAll",
        group: "menuGroup1",
      },
      {
        label: "주니어 과정",
        path: "/courseIntroduce/junior",
        group: "menuGroup1",
      },
      {
        label: "수강절차 안내",
        path: "/enrolment/step",
        group: "menuGroup2",
      },
      {
        label: "수강신청 바로가기",
        path: "/enrolment/newEnrolment",
        group: "menuGroup2",
      },
      {
        label: "수강신청 내역확인",
        path: "/enrolment/classList",
        group: "menuGroup2",
      },
      { label: "FAQ", path: "/learningSupport/faq", group: "menuGroup3" },
      {
        label: "공지사항",
        path: "/learningSupport/noticeList",
        group: "menuGroup3",
      },
    ]);
    const currentPath = computed(() => router.currentRoute.value.path);
    const currentGroup = computed(() => {
      if (currentPath.value.includes("/courseIntroduce")) {
        return "menuGroup1";
      } else if (currentPath.value.includes("/enrolment")) {
        return "menuGroup2";
      } else if (currentPath.value.includes("/learningSupport")) {
        return "menuGroup3";
      }
      return "menuGroup4";
    });
    const accessToken = computed(() => {
      return store.getters.getAccessToken;
    });
    const companyLogo = computed(() => {
      return store.getters.getCompanyLogo;
    });
    const filterMenusByGroup = (group) => {
      return originalMenus.value.filter((menu) => menu.group === group);
    };
    const menus = computed(() => {
      return filterMenusByGroup(currentGroup.value);
    });
    const isMenuActive = (path) => {
      return currentPath.value === path;
    };

    // 모바일 헤더
    const isMobileNavOpen = ref(false);
    const toggleMobileNav = () => {
      isMobileNavOpen.value = !isMobileNavOpen.value;
    };
    const subMenuOpen = ref([false, false, false]);
    const toggleSubMenu = (index) => {
      for (let i = 0; i < subMenuOpen.value.length; i++) {
        subMenuOpen.value[i] = i === index ? !subMenuOpen.value[i] : false;
      }
    };

    onMounted(() => {
      // token 유효성 검사
      // registUserInfo.getCheckToken();

      // 각 페이지 타이틀
      if (router.currentRoute.value.meta.title) {
        pageTitle.value = router.currentRoute.value.meta.title;
      } else {
        pageTitle.value = "평생교육";
      }
      watch(
        () => router.currentRoute.value,
        (to, from) => {
          if (to.meta.title) {
            pageTitle.value = to.meta.title;
          } else {
            pageTitle.value = "평생교육";
          }
        }
      );

      // pc 헤더
      const gnb = document.querySelector(".gnb");
      const subMenus = document.querySelectorAll(".sub");
      const headerBg = document.querySelector(".header_bg");
      const header = document.getElementById("header");
      const gnbMenus = document.querySelectorAll(".gnb > li");

      // 하위 메뉴 최대 높이 계산
      let maxH = 0;
      subMenus.forEach(function (subMenu) {
        const h = parseInt(window.getComputedStyle(subMenu).height);
        if (h > maxH) {
          maxH = h;
        }
      });

      // 하위 메뉴 마우스 갖다댈 시 이벤트 처리
      gnb.addEventListener("mouseenter", function () {
        headerBg.style.height = maxH + "px";
        header.classList.add("open");
      });

      // 하위 메뉴 마우스 벗어날 시 이벤트 처리
      gnb.addEventListener("mouseleave", function () {
        headerBg.style.height = "0";
        header.classList.remove("open");
      });

      // 하위 메뉴에 마우스 오버 및 리브 시 이벤트 처리
      gnbMenus.forEach(function (gnbItems) {
        gnbItems.addEventListener("mouseenter", function () {
          gnbItems.classList.add("active");

          const subLinks = gnbItems.querySelectorAll(".sub li p");
          subLinks.forEach(function (subItems) {
            subItems.addEventListener("mouseenter", function () {
              subItems.style.color = "#f97316";
            });

            subItems.addEventListener("mouseleave", function () {
              subItems.style.color = "black";
            });
          });
        });

        gnbItems.addEventListener("mouseleave", function () {
          gnbItems.classList.remove("active");
        });
      });
    });
    watch(
      () => route.path,
      (nVal, oVal) => {
        updateIsNavList();
      }
    );
    return {
      idxLeSiteMain,
      currentRoute: route,
      goPage,
      registUserInfo,
      accessToken,
      companyLogo,
      openGlobalsugang,
      opencheckGlobalsugang,
      // 메인, 서브 헤더 구분
      isMainPage,

      // 각 페이지 타이틀
      pageTitle,

      // 각 페이지 접속했을 때 하위 메뉴 있으면
      isNavList,
      menus,
      isMenuActive,

      // 모바일 헤더, 메뉴
      isMobileNavOpen,
      toggleMobileNav,
      subMenuOpen,
      toggleSubMenu,
    };
  },
};
</script>
<style scoped>
.pc #header.open .header_bg {
  position: absolute;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  border-top: 1px solid #e5e5e5;
  z-index: 1;
  padding-bottom: 200px; /* 수강신청 버튼 있을때 */
  padding-bottom: 150px; /* 수강신청 버튼 없을때 */
}

.pc #header .pc-nav .active:hover:after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: #f97316;
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>
