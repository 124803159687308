<template>
  <ComHeader></ComHeader>
  <div :class="{ 'pt-[97px]': !isNav, 'pt-[137px]': isNav }" class="pc:pt-[93px]">
    <router-view />
  </div>
  <ComFooter></ComFooter>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import ComHeader from "./components/ComHeader.vue";
import ComFooter from "./components/ComFooter.vue";
import { onMounted, nextTick, reactive, computed } from "vue";
import { useStore } from "vuex";
if (location.origin.includes("life-admin.carrotapply.com") || location.origin.includes("stage-lifeedu-admin.carrotenglish.net")) {
} else {
  import("@lifeeduWeb/assets/css/reset.css");
  import("@lifeeduWeb/assets/css/tailwind.css");
}
import axios from "@lifeeduWeb/plugins/axios.js";

export default {
  name: "App",
  components: { ComHeader, ComFooter },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const isNav = computed(() => {
      const isReturnPath =
        route.path !== "/home" &&
        route.path !== "/login/login" &&
        route.path !== "/myClass" &&
        route.path !== "/myPage" &&
        route.path !== "/signup/signUp1" &&
        route.path !== "/signup/signUp2" &&
        route.path !== "/selfLearning";
      return isReturnPath;
    });

    // 가로 360미만 뷰 고정
    const setViewport = () => {
      const viewport = document.getElementById("viewport");
      if (screen.width < 360) {
        viewport.setAttribute("content", "width=360, maximum-scale=1, user-scalable=no");
      } else {
        viewport.setAttribute(
          "content",
          "width=device-width, user-scalable=no, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1"
        );
      }
    };

    const signUser = reactive({
      homeMainInfoDetail: {
        idxLeSiteMain: "",
        pageNum: 1,
        pageSize: 50,
        homeMainDetailList: [],
        homeMainNoticeList: [],
        idxCompany: "",
        isAdult: "",
        popupImg1: "",
        popupImg2: "",
        popupImg3: "",
        popupImg4: "",
        popupImg5: "",
      },

      getSiteMain() {
        const params = {
          idxLeSiteMain: signUser.homeMainInfoDetail.idxLeSiteMain,
        };

        axios.get(`/user/page/siteMain`, { params }).then(function (res) {
          if (res.data.success === true) {
            signUser.homeMainInfoDetail.homeMainDetailList = res.data.data;
            const companyLogo = signUser.homeMainInfoDetail.homeMainDetailList.companyLogo;
            signUser.homeMainInfoDetail.idxCompany = res.data.data.idxCompany;
            signUser.homeMainInfoDetail.isAdult = res.data.data.isAdult;
            store.dispatch("setCompanyLogo", companyLogo);
            store.commit("setIdxCompany", res.data.data.idxCompany);
            store.commit("setIsCancleFunction", res.data.data.isCancleFunction);
            signUser.homeMainInfoDetail.popupImg1 = res.data.data.popupImg1;
            signUser.homeMainInfoDetail.popupImg2 = res.data.data.popupImg2;
            signUser.homeMainInfoDetail.popupImg3 = res.data.data.popupImg3;
            signUser.homeMainInfoDetail.popupImg4 = res.data.data.popupImg4;
            signUser.homeMainInfoDetail.popupImg5 = res.data.data.popupImg5;
            signUser.homeMainInfoDetail.button1Link = res.data.data.button1Link;
            signUser.homeMainInfoDetail.button1Name = res.data.data.button1Name;
            signUser.homeMainInfoDetail.button2Link = res.data.data.button2Link;
            signUser.homeMainInfoDetail.button2Name = res.data.data.button2Name;
            signUser.homeMainInfoDetail.button3Link = res.data.data.button3Link;
            signUser.homeMainInfoDetail.button3Name = res.data.data.button3Name;
            signUser.homeMainInfoDetail.button4Link = res.data.data.button4Link;
            signUser.homeMainInfoDetail.button4Name = res.data.data.button4Name;
          } else {
            alert(res.data.message);
          }
        });
      },
    });

    // 헤더 높이에 따라 컨텐츠에 padding-top 값 변경
    // const contentPaddingTop = () => {
    //   const headerM = document.querySelector("#header-m");
    //   const headerMHeight = window.getComputedStyle(headerM).height;
    //   const headerP = document.querySelector("#header");
    //   const headerPHeight = window.getComputedStyle(headerP).height;
    //   const contentWrapper = document.querySelector("#content-wrapper");

    //   if (screen.width < 1200) {
    //     contentWrapper.style.paddingTop = `${headerMHeight}`;
    //   } else {
    //     contentWrapper.style.paddingTop = `${headerPHeight}`;
    //   }
    // };

    onMounted(async () => {
      const idxLeSiteMain = store.getters.getIdxLeSiteMain;

      if (idxLeSiteMain) {
        store.commit("setIdxLeSiteMain", idxLeSiteMain);
        signUser.homeMainInfoDetail.idxLeSiteMain = idxLeSiteMain;
      }

      signUser.getSiteMain();

      setViewport();

      await nextTick();
      // contentPaddingTop();

      window.addEventListener("resize", async () => {
        setViewport();

        await nextTick();
        // contentPaddingTop();
      });
    });

    return {
      signUser,
      isNav,
    };
  },
};
</script>
<style scoped></style>
